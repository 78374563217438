import { Splide } from '@splidejs/splide';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel';

export default function() {

    let splideElement = document.querySelector('.points-mobile .splide');

    var splide = new Splide( splideElement, {
        type   : 'slide',
        drag   : 'true',
        gap: '60px',
        padding: '60px',
        pagination: false,
        arrows: false,
        fixedWidth: '205px',
        // fixedHeight: '200px',
        waitForTransition: false,
        // lazyLoad: "nearby",
        swipeDistance: 10,    // Minimum distance needed for a swipe in pixels
        flickPower: 110,      // The power of flick. The larger this number is, the further a slider will scroll.
        // flickMaxPages: 3,     // The maximum pages to move by a flick.
        dragMinThreshold: 75,
    });
    
    splide.mount();

}