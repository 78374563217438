import { Splide } from '@splidejs/splide';

export default function() {

    let splideElement = document.querySelector('.steps-mobile .splide');
    let splideBar = splideElement.querySelector('.my-slider-progress-bar');

    var splide = new Splide( splideElement, {
        autoplay: true,
        type   : 'loop',
        drag   : 'true',
        perPage : 1,
        // gap: '60px',
        // padding: '60px',
        pagination: false,
        arrows: false,
        // fixedWidth: '205px',
        // fixedHeight: '200px',
        waitForTransition: false,
        // lazyLoad: "nearby",
        swipeDistance: 10,    // Minimum distance needed for a swipe in pixels
        flickPower: 110,      // The power of flick. The larger this number is, the further a slider will scroll.
        // flickMaxPages: 3,     // The maximum pages to move by a flick.
        dragMinThreshold: 75,
    });

    let progressInterval;
    let slideDuration = 5000; // The duration of each slide in milliseconds
    let increment = 100; // The amount of time in milliseconds between each width increment
    let progress = 0;

    splide.on('move', function() {
        clearInterval(progressInterval); // Clear the interval when a new slide starts
        progress = 0; // Reset the progress
        splideBar.style.width = '0%'; // Reset the progress bar
    });
    
    splide.on('moved', function() {
        progressInterval = setInterval(() => {
            progress += increment;
            let widthPercentage = (progress / slideDuration) * 100;
            splideBar.style.width = `${widthPercentage}%`;
    
            if (progress >= slideDuration) {
                clearInterval(progressInterval);
                progress = 0;
            }
        }, increment);
    });
    
    splide.mount();

}