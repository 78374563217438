export default function() {

    // Add info about the current year
    const date = new Date();
    const year = date.getFullYear();

    if (year != 2023) {
        const legalDiv = document.querySelector('.footer-wrapper__item.year');
        legalDiv.textContent += ` — ${year}`;
    }


    

}