import Splide from '@splidejs/splide';
import $ from 'jquery';
import 'select2';
import { easepick, RangePlugin, AmpPlugin, LockPlugin, DateTime } from '@easepick/bundle';
import intlTelInput from 'intl-tel-input';
import utils from 'intl-tel-input/build/js/utils';
// import 'intl-tel-input/build/css/intlTelInput.css';
// import SmoothScroll from 'smooth-scroll';

window.easepick = easepick;
window.RangePlugin = RangePlugin;
window.AmpPlugin = AmpPlugin;
window.LockPlugin = LockPlugin;
window.DateTime = DateTime;


export default function() {

    // Get language JSON
    let langData;
    const request = new XMLHttpRequest();
    request.open('GET', '../lang.json');
    request.setRequestHeader('Content-type', 'application/json; charset=utf-8');
    request.send();
    request.addEventListener('load', () => {
        if (request.status === 200) {
            langData = JSON.parse(request.response);
            main();
            // dataLength = Object.keys(langData.trainers).length;
        }
    });

    // Get the language attribute from the html tag
    let pageLang;
    if (document.documentElement.getAttribute('lang') == 'en') {
        pageLang = 'en';
    } else if (document.documentElement.getAttribute('lang') == 'ru') {
        pageLang = 'ru';
    } else if (document.documentElement.getAttribute('lang') == 'cnr') {
        pageLang = 'cnr';
    }

    // CRM
    let token,
        chosenCarId,
        carSum,
        deliveryTotal = 0;
    
    // Declare variables for functions for generating fleet cards
    let generateFleetCards,
        generateFleetCardsOnDates;
        
    let datesPickerModal,
        lockPlugin,
        modalButtonFunction,
        modalPlacePickupFunction,
        modalPlaceDropoffFunction,
        modalWaitTimeout,
        modalWaitCountdown,
        modalTel,
        modalTelIti;

    // Variable to determine if variables were shown (for a sufficent amount of time so that the loading animation could be seen)
    let dummiesShown = true,
        modalIsClosing = false;

    // Fn for activating dummy fleet cards with loading animation (first row active by default on page load)
    function showDummyFleetCards(fleetWrapperDestiny) {
        const fleetWrapper = document.querySelector('.fleet-wrapper'),
              dummyWrappers = document.querySelectorAll('.fleet-dummy');

        dummiesShown = false;

        let dummyRows = 1;
        if (fleetWrapper.children.length > 0) {
            dummyRows = Math.ceil(fleetWrapper.children.length / 3);
            dummyRows = Math.min(dummyRows, 3);
        }

        for (let i = 0; i < dummyRows; i++) {
            dummyWrappers[i].classList.add('fleet-dummy_active');
            setTimeout(function() {
                // Variable to determine if variables were shown (for a sufficent amount of time so that the loading animation could be seen)
                dummiesShown = true;
            }, 500);
        }

        if (fleetWrapperDestiny == 'clear' || fleetWrapperDestiny == '' || typeof fleetWrapperDestiny === 'undefined') {
            fleetWrapper.innerHTML = '';
            fleetWrapper.classList.remove('fleet-wrapper_active');
        } else if (fleetWrapperDestiny == 'hide') {
            fleetWrapper.classList.remove('fleet-wrapper_active');
        }
    }

    // THE MAIN FUNCTION, to launch after lang.json is loaded
    function main() {



    fetch('./../php/rentAuth.php')
        .then(response => response.text())
        .then(data => {
            // Store token in a variable
            token = data;

            fetch('./../php/rentGetInitInfo.php?token='+token)
                .then(response => response.json())
                .then(data => {

                    console.log(data); // we get main info here (ie locations)

                    function createLocationOptions(locations) {
                        let options = '';
                      
                        locations.forEach(location => {
                            const name = langData.locations[location.name][pageLang] || location.name;
                            options += `<option value="${location.id}">${name}</option>`;
                        });
                      
                        return options;
                    }

                    const locationData = data.locations;

                    const locationOptions = createLocationOptions(locationData);
                    console.log("locationOptions:", locationOptions);

                    // Find all select elements on the page you want to replace the options for, e.g., with a class "location-select"
                    const locationSelects = document.querySelectorAll('.location-select');

                    // Replace the options for each select element
                    locationSelects.forEach(select => {
                        select.innerHTML = locationOptions;
                    });


                    if ((data.status >= 300 && data.status <= 526) || data.status === 'error') {
                        // show error message
                        // document.querySelector('.fleet-error').classList.add('fleet-error_active');

                        // // hide dummy fleet-cards
                        // document.querySelectorAll('.fleet-dummy').forEach(dummy => {
                        //     dummy.classList.remove('fleet-dummy_active');
                        // });

                        return;
                    }

                })
                .catch(error => {
                    console.log('error', error);
                    // ВНИМАНИЕ display error message
                });

            fetch('./../php/rentGetCars.php?token='+token)
                .then(response => response.json())
                .then(data => {

                    if ((data.status >= 300 && data.status <= 526) || data.status === 'error') {
                        // ВНИМАНИЕ display error message

                        console.log('Error from 300 to 526, or just "error", data:', data);

                        return;
                    }

                    // write the code here, but the function was declared before in global scope
                    generateFleetCards = function(vehicle) {

                        const fleetWrapper = document.querySelector('.fleet-wrapper');
                        
                        // clear fleet-wrapper and generate dummy fleet-cards for loading animation
                        if (!document.querySelector('.fleet-dummy_active')) {
                            showDummyFleetCards('clear');
                        }

                        let promises = [];


                        for (let i = 0; i < vehicle.length; i++) {
                            promises.push(new Promise(resolve => {

                                console.log(vehicle[i]);

                                let carId,
                                    mainPhoto,
                                    allPhotos,
                                    allPhotosQty,
                                    transmissionName = '',
                                    transmissionValue = '',
                                    fuel = '',
                                    engineVolume = '',
                                    engineText = '',
                                    brand = '',
                                    mark = '',
                                    seats = '',
                                    year = '',
                                    largeBags = '',
                                    minPrice,
                                    option1 = '',
                                    option2 = '',
                                    carText = '';

                                if (vehicle[i].transmission == 'Automatic (AT)') {
                                    transmissionName = langData.car.char.automatic[pageLang];
                                    transmissionValue = 'automatic';
                                } else if (vehicle[i].transmission == 'Manual (MT)') {
                                    transmissionName = langData.car.char.manual[pageLang];
                                    transmissionValue = 'manual';
                                }


                                if (vehicle[i].id) {
                                    carId = vehicle[i].id;
                                }
                                if (vehicle[i].thumbnail) {
                                    mainPhoto = vehicle[i].thumbnail;
                                }
                                if (vehicle[i].photos) {
                                    allPhotos = vehicle[i].photos;
                                    allPhotosQty = vehicle[i].photos.length;
                                }
                                if (vehicle[i].brand) {
                                    brand = vehicle[i].brand.toLowerCase().replace(/\b\w/g, c => c.toUpperCase());
                                }
                                if (vehicle[i].mark) {
                                    mark = vehicle[i].mark.toLowerCase().replace(/\b\w/g, c => c.toUpperCase());
                                }
                                if (vehicle[i].number_seats) {
                                    seats = vehicle[i].number_seats;
                                }
                                if (vehicle[i].fuel) {
                                    if (vehicle[i].fuel == 'Petrol' || vehicle[i].fuel == 'Gasoline') {
                                        fuel = langData.car.char.petrol[pageLang];
                                    } else if (vehicle[i].fuel == 'Diesel') {
                                        fuel = langData.car.char.diesel[pageLang];
                                    }
                                }
                                if (vehicle[i].volume_engine) {
                                    let volToString = String(vehicle[i].volume_engine);
                                    let firstTwoDigits = volToString.slice(0, 2);
                                    engineVolume = firstTwoDigits[0] + '.' + firstTwoDigits[1];
                                    engineText = langData.car.designations.engine[pageLang];
                                } 
                                if (vehicle[i].volume_engine && vehicle[i].type == 'Велосипед') {
                                    engineVolume = vehicle[i].volume_engine;
                                    engineText = langData.car.designations.cc[pageLang];
                                }
                                if (vehicle[i].year) {
                                    year = vehicle[i].year;
                                }
                                if (vehicle[i].large_bags) {
                                    largeBags = vehicle[i].large_bags;
                                }
                                
                                
                                const hasBluetooth = vehicle[i].options[0].some(option => option.id === 'bluetooth'),
                                      hasUsb = vehicle[i].options[0].some(option => option.id === 'audio_ipod'),
                                      hasNavigation = vehicle[i].options[0].some(option => option.id === 'built_in_sat_nav');

                                if (hasNavigation) {
                                    option1 = `${langData.car.feat.navigation[pageLang]}`;
                                } 
                                if (hasUsb) {
                                    option1 = 'USB';
                                } 
                                if (hasBluetooth) {
                                    option1 = 'Bluetooth';
                                }
                                
                                const hasRearCamera = vehicle[i].options[0].some(option => option.id === 'rearview_camera'),
                                      hasParkingSensors = vehicle[i].options[0].some(option => option.id === 'parking_sensors'),
                                      hasParkingSensorsRear = vehicle[i].options[0].some(option => option.id === 'rear_parking_sensors'),
                                      hasCruise = vehicle[i].options[0].some(option => option.id === 'cruise_control');

                                if (hasCruise) {
                                    option2 = `${langData.car.feat.cruiseControl[pageLang]}`;
                                }
                                if (hasParkingSensorsRear) {
                                    option2 = `${langData.car.feat.parkingSensors[pageLang]}`;
                                }
                                if (hasParkingSensors) {
                                    option2 = `${langData.car.feat.parkingSensors[pageLang]}`;
                                }
                                if (hasRearCamera) {
                                    option2 = `${langData.car.feat.rearCamera[pageLang]}`;
                                }



                                // car description text
                                if (pageLang == 'en' && vehicle[i].custom_fields.find(obj => obj.title === 'textEn')) {
                                    carText = vehicle[i].custom_fields.find(obj => obj.title === 'textEn').value;
                                }
                                if (pageLang == 'ru' && vehicle[i].custom_fields.find(obj => obj.title === 'textRu')) {
                                    carText = vehicle[i].custom_fields.find(obj => obj.title === 'textRu').value;
                                }
                                if (pageLang == 'cnr' && vehicle[i].custom_fields.find(obj => obj.title === 'textCnr')) {
                                    carText = vehicle[i].custom_fields.find(obj => obj.title === 'textCnr').value;
                                }


                                let minPriceToDisplay;

                                if (vehicle[i].periods_price) {
                                    // IF no dates chosen, display car minimal price
                                    let periods = vehicle[i].periods_price;
                                    
                                    let maxDiscount = 0;
                                    
                                    for (let i = 0; i < periods.length; i++) {
                                    let discount = periods[i].discount;
                                        // check if there is a viable discount, and stop at the one closest but not more that 40%
                                        if (discount < maxDiscount && discount >= -40) {
                                            maxDiscount = discount;
                                        }
                                    }
                                    
                                    minPriceToDisplay = parseInt(vehicle[i].price);
                                    let discountAmount = (minPriceToDisplay * maxDiscount) / 100;
                                    console.log("discountAmount:", discountAmount);
                                    console.log("maxDiscount:", maxDiscount);
                                    minPriceToDisplay = minPriceToDisplay + discountAmount;
                                    minPriceToDisplay = langData.buttons.from[pageLang] + ' ' + '€' + minPriceToDisplay;
                                } else if (vehicle[i].total_price) {
                                    // IF dates chosen, display total price
                                    minPriceToDisplay = '€' + parseInt(vehicle[i].total_price.replace(/\s/g, ''));
                                }


                                // create a card and append info
                                fleetWrapper.insertAdjacentHTML('beforeend', `
                                    <div class="fleet-card">
                                        <img src="${mainPhoto}" class="fleet-card__image cover" aria-hidden="true"></img>
                                        <h3 class="fleet-card__name">${brand + ' ' + mark + ', ' + year}</h3>
                                        <div class="fleet-card__features">
                                            <div class="fleet-card__features-item" value='${transmissionValue}'>${transmissionName}</div>
                                            <div class="fleet-card__features-item" value='${seats}seats'>${seats} ${langData.car.designations.seats[pageLang]}</div>
                                            <div class="fleet-card__features-item" value='${engineVolume}'>${engineVolume} ${engineText}</div>
                                            <div class="fleet-card__features-item" value='vehicle[i].fuel'>${fuel}</div>
                                            <div class="fleet-card__features-item" value='${option1}'>${option1}</div>
                                            <div class="fleet-card__features-item" value='${option2}'>${option2}</div>
                                        </div>
                                        <div class="fleet-card__bottom">
                                            <div class="fleet-card__bottom-price">${minPriceToDisplay}</div>
                                            <button class="fleet-card__bottom-button">${langData.buttons.choose[pageLang]}</button>
                                        </div>
                                        <div class="fleet-card__info" 
                                            data-carId="${carId}"
                                            data-transmission="${transmissionValue}"
                                            data-fuel="${fuel}"
                                            data-engineVolume="${engineVolume}"
                                            data-engineText="${engineText}"
                                            data-mainPhoto="${mainPhoto}"
                                            data-allPhotos="${allPhotos}"
                                            data-allPhotosQty="${allPhotosQty}"
                                            data-brand="${brand}"
                                            data-mark="${mark}"
                                            data-largeBags="${largeBags}"
                                            data-seats="${seats}"
                                            data-year="${year}"
                                            data-carText="${carText}"
                                            data-option1="${option1}"
                                            data-option2="${option2}"
                                        </div>
                                    </div>
                                `);



                                resolve();
                            }));
                        }

                        Promise.all(promises).then(() => {
                            // console.log("minPriceToDisplay:", minPriceToDisplay);
                            
                            // filters fleet-cards if any filters selected
                            filterCardsWithOptions();

                            // launch general function for modal window opening
                            generalModalWindowOpening();
                        });

                    };
                    generateFleetCards(data);
                    // end of the function that generates fleet cards

                })
                .catch(error => {
                    console.log('error', error);
                    // ВНИМАНИЕ display error message
                });

        })
        .catch(error => {
            console.log('error', error);
            // ВНИМАНИЕ display error message
        });
    // end of fetch



    function generalModalWindowOpening() {


        // MODAL WINDOW OPENING by clicking card buttons
        
        const cardButtons = document.querySelectorAll('.fleet-card__bottom-button'),
                modal = document.querySelector('.fleet-modal'),
                modalCloseButton = document.querySelector('.fleet-modal__close'),

                fog = document.querySelector('.fog');

        cardButtons.forEach((item, index) => {
            item.addEventListener('click', e => {
                let infoElement = e.target.closest('.fleet-card').querySelector('.fleet-card__info');
    
                // Retrieve value of the data-text-en attribute
                let carId = infoElement.getAttribute('data-carId'),
                    mainPhoto = infoElement.getAttribute('data-mainPhoto'),
                    allPhotos = infoElement.getAttribute('data-allPhotos'),
                    allPhotosQty = infoElement.getAttribute('data-allPhotosQty'),
                    brand = infoElement.getAttribute('data-brand'),
                    mark = infoElement.getAttribute('data-mark'),
                    transmission = infoElement.getAttribute('data-transmission'),
                    largeBags = infoElement.getAttribute('data-largeBags'),
                    seats = infoElement.getAttribute('data-seats'),
                    year = infoElement.getAttribute('data-year'),
                    option1 = infoElement.getAttribute('data-option1'),
                    option2 = infoElement.getAttribute('data-option2'),
                    fuel = infoElement.getAttribute('data-fuel'),
                    engineVolume = infoElement.getAttribute('data-engineVolume'),
                    engineText = infoElement.getAttribute('data-engineText'),
                    carText = infoElement.getAttribute('data-carText');

                let checkCounter = 0;
                const maxChecks = 30;
                
                const intervalId = setInterval(() => {
                    if (!modalIsClosing) {
                        clearInterval(intervalId);
                        openModal(carId, mainPhoto, allPhotos, allPhotosQty, brand, mark, transmission, largeBags, seats, option1, option2, fuel, engineVolume, engineText, carText);
                    } else if (checkCounter >= maxChecks) {
                        clearInterval(intervalId);
                        console.log('Reached the maximum number of checks');
                    } else {
                        checkCounter++;
                    }
                  }, 100);
            });
        });
    

        // Fn for opening the modal window
        function openModal(carId, mainPhoto, allPhotos, allPhotosQty, brand, mark, transmission, largeBags, seats, option1, option2, fuel, engineVolume, engineText, carText) {

            chosenCarId = carId;

            let datePickup, dateDropoff, startDate, endDate;

            // Place loading elements (decorative), to remove them before placing the data
            let optionsWrapper = document.querySelector('.fleet-modal__book-options__wrapper');
            for (let i = 0; i < 5; i++) {
                optionsWrapper.insertAdjacentHTML('afterbegin', `
                    <div class="fleet-modal__book-options__item loading">
                `);
            }


            // Move chat button to the top of the screen
            if (window.innerWidth < 960) {
                document.querySelector('#chat').style.top = '1rem';
                document.querySelector('#chat').style.left = '1rem';
                document.querySelector('#chat').style.bottom = 'unset';
                document.querySelector('#chat').style.right = 'unset';
            }


            // The booking button
            const bookButton = document.querySelector('.fleet-modal__book-button');
            if (datesPickerModal.getStartDate() && datesPickerModal.getEndDate() && bookButton.classList.contains('fleet-modal__book-button_disabled')) {
                document.querySelector('.fleet-modal__book-button').classList.remove('fleet-modal__book-button_disabled');
            } else if (!datesPickerModal.getStartDate() && !datesPickerModal.getEndDate() && !bookButton.classList.contains('fleet-modal__book-button_disabled')) {
                document.querySelector('.fleet-modal__book-button').classList.add('fleet-modal__book-button_disabled');
            }

            // Get busy dates for the picked car, and lock them in the calendar. Then call fetchCarData()
            fetch(`./../php/carBusyDates.php?vehicle_id=${carId}&token=${token}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
            })
                .then(response => response.json())
                .then(data => {

                    if ((data.status >= 300 && data.status <= 526) || data.status === 'error') {
                        // ВНИМАНИЕ display error message

                        return;
                    }

                    let dates = data.dates;
                    console.log("dates:", dates);

                    // easepick calendar lock days
                    if (dates.length > 0) {

                        const bookedDates = createDateArray(dates);

                        lockPlugin.options.filter = function(date, picked) {
                            return isDateInArray(bookedDates, date);
                        };
                        datesPickerModal.renderAll();
                    }

                    // get booked days array and sort it chronologically
                    // dates.sort(function(a, b) {
                    //     return new Date(a.from) - new Date(b.from);
                    // });
                    // console.log("car busy days (sorted)", dates);

                    console.log('fetchCarData() 1');
                    fetchCarData(dates);

                })
                .catch(error => {
                    console.log('error', error);
                    // ВНИМАНИЕ display error message
                });

            
            let retryCount = 0;

            function fetchCarData(bookedDates) {
                
                // Define datePickup and dateDropoff
                // let datePickup, dateDropoff, startDate, endDate;

                // If dates are selected, get them from a calendar
                if (document.querySelector('.fleet-modal_active') && datesPickerModal.getStartDate() && datesPickerModal.getEndDate()) {

                    // if modal is already opened, get dates from the modal calendar
                    startDate = datesPickerModal.getStartDate();
                    endDate = datesPickerModal.getEndDate();

                } else if (!document.querySelector('.fleet-modal_active') && datesPickerHeader.getStartDate() && datesPickerHeader.getEndDate()) {

                    // if modal is not opened, get dates from the header calendar
                    startDate = datesPickerHeader.getStartDate();
                    endDate = datesPickerHeader.getEndDate();
                    
                }

                if (startDate && endDate) {

                    // If dates are selected
                    const startYear = startDate.getFullYear();
                    const startMonth = startDate.getMonth();
                    const startDay = startDate.getDate();

                    const endYear = endDate.getFullYear();
                    const endMonth = endDate.getMonth();
                    const endDay = endDate.getDate();


                    let rangeOverlapFound;
                    for (let i = 4; i >= 1; i--) {
                        const from = new Date(startYear + i, startMonth, startDay);
                        const to = new Date(endYear + i, endMonth, endDay);
                        // console.log("new from to test:", from);
                        // console.log("new to to test:", to);

                        rangeOverlapFound = false;
                        for (let j = 0; j < bookedDates.length; j++) {
                            const bookedStartDate = new Date(bookedDates[j].from.replace(/-/g, '/'));
                            const bookedEndDate = new Date(bookedDates[j].to.replace(/-/g, '/'));

                            bookedStartDate.setHours(0, 0, 0, 0);
                            bookedEndDate.setHours(2, 3, 5, 9);
                            
                            // console.log("testing bookedStartDate:", bookedStartDate);
                            // console.log("testing bookedEndDate:", bookedEndDate);
                            if ((from >= bookedStartDate && to <= bookedEndDate) ||
                                (from <= bookedStartDate && to >= bookedEndDate) ||
                                (from <= bookedStartDate && to >= bookedStartDate && to <= bookedEndDate) ||
                                (from >= bookedStartDate && from <= bookedEndDate && to >= bookedEndDate)) {
                                rangeOverlapFound = true;
                                break;
                            }
                        }

                        if (!rangeOverlapFound) {
                            // console.log('slot found, proceeding to creating a hold');

                            // get time from the modal and set it for booking
                            let timePickup = $('#timePickupModal').val(),
                                timeDropoff = $('#timeDropoffModal').val();
                            from.setHours(Number(timePickup.split(':')[0]), Number(timePickup.split(':')[1]), 0, 0);
                            to.setHours(Number(timeDropoff.split(':')[0]), Number(timeDropoff.split(':')[1]), 0, 0);
    
                            // convert the dates to CET timezone
                            let tzoffset2 = from.getTimezoneOffset() * 60000;
                            let localISOTime3 = (new Date(from - tzoffset2)).toISOString().slice(0, 19).replace('T', ' ');
                            datePickup = localISOTime3;
                            let tzoffset3 = to.getTimezoneOffset() * 60000;
                            let localISOTime4 = (new Date(to - tzoffset3)).toISOString().slice(0, 19).replace('T', ' ');
                            dateDropoff = localISOTime4;
                      
                            // console.log("datePickup:", datePickup);
                            // console.log("dateDropoff:", dateDropoff);

                            break;
                        }

                        // if we went back to this year or further, cancel the booking and display a message with a timer == hold time set in CRM
                        if (from.getFullYear() < (new Date()).getFullYear() + 1) {
                            console.log('No available time slot was found.');

                            let holdTime = 60,
                            holdTimeMs = holdTime * 1000;
                            
                            document.querySelector('.fleet-modal__book-options').classList.add('fleet-modal__book-options_hide');
                            document.querySelector('.fleet-modal__book-wait').classList.add('fleet-modal__book-wait_show');
                            document.querySelector('.fleet-modal__book-button').classList.add('fleet-modal__book-button_disabled');

                            modalWaitCountdown = startCountdown(holdTime, document.querySelector(".fleet-modal__book-wait__timer"));

                            if (document.querySelector('.fleet-modal__book-options__item.loading')) {
                                const loadingItems = document.querySelectorAll('.fleet-modal__book-options__item.loading');
                                for (let i = 0; i < loadingItems.length; i++) {
                                    loadingItems[i].remove();
                                }
                            }

                            modalWaitTimeout = setTimeout(() => {
                                document.querySelector('.fleet-modal__book-options').classList.remove('fleet-modal__book-options_hide');
                                document.querySelector('.fleet-modal__book-wait').classList.remove('fleet-modal__book-wait_show');
                                openModal(carId, mainPhoto, allPhotos, allPhotosQty, brand, mark, transmission, largeBags, seats, option1, option2, fuel, engineVolume, engineText, carText);
                            }, holdTimeMs);

                            return;
                        }
                        
                    }

                    // if in the end no slot was found, cancel the booking and display a message with a timer == hold time set in CRM
                    if (rangeOverlapFound) {
                        console.log('no slot found, cancelling the booking');

                        let holdTime = 60,
                            holdTimeMs = holdTime * 1000;
                        
                        document.querySelector('.fleet-modal__book-options').classList.add('fleet-modal__book-options_hide');
                        document.querySelector('.fleet-modal__book-wait').classList.add('fleet-modal__book-wait_show');
                        document.querySelector('.fleet-modal__book-button').classList.add('fleet-modal__book-button_disabled');

                        modalWaitCountdown = startCountdown(holdTime, document.querySelector(".fleet-modal__book-wait__timer"));

                        if (document.querySelector('.fleet-modal__book-options__item.loading')) {
                            const loadingItems = document.querySelectorAll('.fleet-modal__book-options__item.loading');
                            for (let i = 0; i < loadingItems.length; i++) {
                                loadingItems[i].remove();
                            }
                        }

                        modalWaitTimeout = setTimeout(() => {
                            document.querySelector('.fleet-modal__book-options').classList.remove('fleet-modal__book-options_hide');
                            document.querySelector('.fleet-modal__book-wait').classList.remove('fleet-modal__book-wait_show');
                            openModal(carId, mainPhoto, allPhotos, allPhotosQty, brand, mark, transmission, largeBags, seats, option1, option2, fuel, engineVolume, engineText, carText);
                        }, holdTimeMs);
                        
                        return;
                    }

                } else {

                    // If dates are not selected -- make a dummy booking, for than find farthest year with the current dates (today to tomorrow), to get all the necessary info (this way seasonal discounts/surcharges will be applied and we will get a relevant total to show). Also took an extra measure: if all today-tomorrows are booked, we will +2 days and go from the farthest year again
                    
                    // find farthest year with the current dates (today to tomorrow) for a dummy booking to return all the necessary info (this way seasonal discounts/surcharges will be applied and we will get a relevant total to show)
                            
                    let currentDate = new Date();
                    let currentYear = currentDate.getFullYear();
                    let currentMonth = currentDate.getMonth();
                    let currentDay = currentDate.getDate();

                    let rangeNotFound = true;
                    let rangeOverlapFound;

                    let whileRunCount = 0;
                    while (rangeNotFound) {
                        for (let i = 4; i >= 1; i--) {
                          const from = new Date(currentYear + i, currentMonth, currentDay);
                          const to = new Date(currentYear + i, currentMonth, currentDay + 1);
                          rangeOverlapFound = false;
                          for (let j = 0; j < bookedDates.length; j++) {
                            const dateRange = bookedDates[j];
                            const startDate = new Date(dateRange.from.replace(/-/g, '/'));
                            const endDate = new Date(dateRange.to.replace(/-/g, '/'));

                            startDate.setHours(0, 0, 0, 0);
                            endDate.setHours(2, 3, 5, 9);

                            if (from >= startDate && to <= endDate) {
                                rangeOverlapFound = true;
                                break;
                            }
                          }
                          if (!rangeOverlapFound) {
                            // get time from the modal and set it for booking
                            let timePickup = $('#timePickupModal').val(),
                                timeDropoff = $('#timeDropoffModal').val();
                            from.setHours(Number(timePickup.split(':')[0]), Number(timePickup.split(':')[1]), 0, 0);
                            to.setHours(Number(timeDropoff.split(':')[0]), Number(timeDropoff.split(':')[1]), 0, 0);

                            // convert the dates to CET timezone
                            let tzoffset2 = from.getTimezoneOffset() * 60000;
                            let localISOTime3 = (new Date(from - tzoffset2)).toISOString().slice(0, 19).replace('T', ' ');
                            datePickup = localISOTime3;
                            let tzoffset3 = to.getTimezoneOffset() * 60000;
                            let localISOTime4 = (new Date(to - tzoffset3)).toISOString().slice(0, 19).replace('T', ' ');
                            dateDropoff = localISOTime4;
                      
                            // console.log("datePickup:", datePickup);
                            // console.log("dateDropoff:", dateDropoff);
                      
                            rangeNotFound = false;
                            break;
                            // return {
                            //   from: from,
                            //   to: to
                            // };
                          }
                        }
                      
                        // if all today-tomorrows in the next 1-4 years are booked, we will +2 days and go from the farthest year again
                        currentDay += 2;

                        whileRunCount++;
                        if (whileRunCount > 100) {
                            console.log('tried to find a date range for a dummy booking 100 times, but failed, cancelling the booking');
                            return;
                        }
                    }

                }


                // remove ':00' seconds
                console.log("datePickup before split:", datePickup);
                console.log("dateDropoff before split:", dateDropoff);
                if ((datePickup.match(/:/g) || []).length === 2) {
                    datePickup = datePickup.slice(0, -3);
                }
                
                if ((dateDropoff.match(/:/g) || []).length === 2) {
                    dateDropoff = dateDropoff.slice(0, -3);
                }
                console.log("datePickup after split:", datePickup);
                console.log("dateDropoff after split:", dateDropoff);

                let dates = `${datePickup} - ${dateDropoff}`;
                let placePickup, placeDropoff;

                console.log("dates:", dates);

                if (document.querySelector('.fleet-modal_active')) {
                    // if modal window is active -- get places from there
                    placePickup = $('#placePickupModal').val();
                    placeDropoff = $('#placeDropoffModal').val();
                } else {
                    // if modal window is not active -- get places from the 'header' form
                    placePickup = $('#placePickup').val();
                    placeDropoff = $('#placeDropoff').val();
                }
    
                let url = `./../php/carBooking.php?dates=${dates}&pickup_location=${placePickup}&return_location=${placeDropoff}&token=${token}&vehicle_id=${carId}`;

                console.log("url:", url);

                // get info about the car from the CRM
                fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                    }
                })
                .then(response => response.json())
                .then(data => {

                    if (data.status == 500) {
                        console.log('500:', data);
                        return;
                        // ВНИМАНИЕ здесь нужно добавить итерацию по годам

                        // retryCount++;
                        // if (retryCount < 5) {
                        //     console.log(data);
                        //     console.log(`Retrying request (${retryCount})...`);
                        //     fetchCarData();
                        // } else {
                        //     console.log('no luck ;(');
                        // }
                    } else if (data.status == 404) {
                        console.log('404:', data);
                        return;
                    } else {
                        console.log('car data:', data);

                        carSum = parseInt(data.total_price.replace(/\s/g, ''));
                        console.log("carSum:", carSum);

                        // put Options, Insturances, and Delivery data inside

                        // Insurances
                        if (data.insurances) {
                            // clear the optionsWrapper of loading dummy items
                            optionsWrapper.innerHTML = '';

                            for (let i = data.insurances.length - 1; i >= 0; i--) {
                                let price,
                                    disabled,
                                    counter;
                                if (parseInt(data.insurances[i].value) == '0') {
                                    price = langData.buttons.free[pageLang];
                                    // if price is 0, make it ununselectable
                                    disabled = 'disabled';
                                    counter = 'single';
                                } else if (parseInt(data.insurances[i].value) > 0 && data.insurances[i].type == 'Price') {
                                    // tariff price, put /day
                                    price = `€${parseInt(data.insurances[i].value)}/${langData.buttons.day[pageLang]}`;
                                    disabled = '';
                                    counter = 'multiple';
                                }  else if (parseInt(data.insurances[i].value) > 0 && data.insurances[i].type == 'Fix') {
                                    // fixed price, display just the sum
                                    price = `€${parseInt(data.insurances[i].value)}`;
                                    disabled = '';
                                    counter = 'single';
                                }
                                
                                // determine translations for the insurances
                                let insuranceTitle;
                                if (data.insurances[i].title == 'Basic insurance') {
                                    insuranceTitle = langData.insurance.basic[pageLang];
                                } else if (data.insurances[i].title == 'Full Coverage with 10% deductible') {
                                    insuranceTitle = langData.insurance.casco10pc[pageLang];
                                } else if (data.insurances[i].title == 'Full Coverage') {
                                    insuranceTitle = langData.insurance.cascoFull[pageLang];
                                } else {
                                    // if no translation found -- use the original title from the CRM
                                    insuranceTitle = data.insurances[i].title;
                                }

                                optionsWrapper.insertAdjacentHTML('afterbegin', `
                                    <div class="fleet-modal__book-options__item">
                                        <div class="fleet-modal__book-options__item-tech">
                                            <input id="option${data.insurances[i].id}" type="checkbox" ${disabled}>
                                            <label for="option${data.insurances[i].id}">${insuranceTitle}</label>
                                        </div>
                                        <div class="fleet-modal__book-options__item-dots"></div>
                                        <span class="fleet-modal__book-options__item-price">${price}</span>
                                        <div class="fleet-modal__book-options__item-info" 
                                            data-price="${parseInt(data.insurances[i].value)}"
                                            data-counter=${counter}
                                            data-type="insurance"
                                            data-id="${data.insurances[i].id}"
                                        ></div>
                                    </div>
                                `);

                                // check if the insurance is compulsory, and if so, check it and make it unclickable
                                let option = document.querySelector(`.fleet-modal__book-options__item`);
                                if (option.querySelector('.fleet-modal__book-options__item-info').getAttribute('data-price') == '0') {
                                    option.classList.add('compulsory');
                                    option.querySelector('input').checked = true;
                                }

                                // specify the deposit amount, taking it from the basic insurance
                                if (parseInt(data.insurances[i].deposit_price) != '0') {
                                    document.querySelector('.fleet-modal__book-total__deposit').innerHTML = `
                                        + ${langData.buttons.deposit[pageLang]} <span>€${parseInt(data.insurances[i].deposit_price)}</span>
                                    `;
                                }
                            }

                        }


                        // Options
                        if (data.options) {
                            for (let i = 0; i < data.options.length; i++) {

                                let price,
                                    type;

                                if (parseInt(data.options[i].price_day) == '0') {
                                    price = langData.buttons.free[pageLang];
                                } else if (data.options[i].type == 'fix') {
                                    price = `€${parseInt(data.options[i].price_day)}`;
                                } else if (data.options[i].type == 'tariff') {
                                    price = `€${parseInt(data.options[i].price_day)}/${langData.buttons.day[pageLang]}`;
                                }

                                if (data.options[i].type == 'fix') {
                                    // If the option is a fix, then the price is fixed and doesn't depend on the number of days
                                    type = 'single';
                                } else if (data.options[i].type == 'tariff') {
                                    // If the option is a tariff, then the price is calculated based on the number of days
                                    type = 'multiple';
                                }


                                let quantitySelect = '';
                                if (data.options[i].max_quantity > 1) {
                                    quantitySelect = `<select id="optionQuantity${data.options[i].id}" class="fleet-modal__book-options__item-tech__quantity">`;

                                    for (let j = 1; j <= data.options[i].max_quantity; j++) {
                                        quantitySelect += `<option value="${j}">${j}</option>`;
                                    }

                                    quantitySelect += `</select><label for="optionQuantity${data.options[i].id}" class="screenReaderOnly">Quantity</label>`;
                                }

                                // get the title of the option in the correct language
                                let optionsTitle;
                                if (data.options[i].title == 'Child seat') {
                                    optionsTitle = langData.options.childSeat[pageLang];
                                } else if (data.options[i].title == 'Weel chains') {
                                    optionsTitle = langData.options.wheelChains[pageLang];
                                } else if (data.options[i].title == 'Wifi-router') {
                                    optionsTitle = langData.options.wifiRouter[pageLang];
                                } else if (data.options[i].title == 'Scooter (Xiaomi Essential)') {
                                    optionsTitle = langData.options.scooter[pageLang];
                                } else if (data.options[i].title == 'Helmet') {
                                    optionsTitle = langData.options.helmet[pageLang];
                                } else {
                                    optionsTitle = data.options[i].title;
                                }

                                optionsWrapper.insertAdjacentHTML('beforeend', `
                                    <div class="fleet-modal__book-options__item">
                                            <div class="fleet-modal__book-options__item-tech">
                                                <input id="option${data.options[i].id}" type="checkbox">
                                                <label for="option${data.options[i].id}">${optionsTitle}</label>
                                                ${quantitySelect}
                                            </div>
                                            <div class="fleet-modal__book-options__item-dots"></div>
                                            <span class="fleet-modal__book-options__item-price">${price}</span>
                                            <div class="fleet-modal__book-options__item-info" 
                                                data-price="${parseInt(data.options[i].price_day)}"
                                                data-counter="${type}"
                                                data-type="option"
                                                data-id="${data.options[i].id}"
                                            ></div>
                                        </div>
                                `);

                                // initialize select2 for quanity select
                                if (data.options[i].max_quantity > 1) {
                                    $(`#optionQuantity${data.options[i].id}`).select2({
                                        minimumResultsForSearch: Infinity,
                                        scrollAfterSelect: false
                                        // width: '100px'
                                    });

                                    $(`#optionQuantity${data.options[i].id}`).on('change', function() {
                                        sumEval();
                                    });
                                }
                            }
                        }


                        // Delivery
                        deliveryTotal = 0;
                        if (data.delivery_data.length != 0) {
                            for (let i = 0; i < data.delivery_data.length; i++) {

                                let deliveryText;
                                if (data.delivery_data[i].type == 'delivery') {
                                    deliveryText = langData.delivery.pickup[pageLang];
                                } else if (data.delivery_data[i].type == 'return_delivery') {
                                    deliveryText = langData.delivery.dropoff[pageLang];
                                }

                                optionsWrapper.insertAdjacentHTML('beforeend', `
                                    <div class="fleet-modal__book-options__item compulsory delivery">
                                        <div class="fleet-modal__book-options__item-tech">
                                            <input id="optionDelivery" type="checkbox" checked="true" disabled>
                                            <label for="optionDelivery">${deliveryText}</label>
                                        </div>
                                        <div class="fleet-modal__book-options__item-dots"></div>
                                        <span class="fleet-modal__book-options__item-price">€${parseInt(data.delivery_data[i].price)}</span>
                                        <div class="fleet-modal__book-options__item-info" 
                                            data-price="${parseInt(data.delivery_data[i].price)}"
                                            data-counter="single"
                                            data-type="delivery"
                                        ></div>
                                    </div>
                                `);

                                deliveryTotal += parseInt(data.delivery_data[i].price);
                            }
                        }


                        renderDecorDots();
                        setTimeout(sumEval, 250);


                        // Add event listeners to the options checkboxes
                        document.querySelectorAll('.fleet-modal__book-options__item input[type="checkbox"]').forEach((checkbox) => {
                            checkbox.addEventListener('change', () => {
                                // Get the price and counter info from the parent element
                                const dataElement = checkbox.closest('.fleet-modal__book-options__item').querySelector('.fleet-modal__book-options__item-info');
                                const type = dataElement.dataset.type;

                                // If insurance checkbox, then uncheck all the other insurance checkboxes
                                if (type == 'insurance') {
                                    const insuranceElements = document.querySelectorAll('.fleet-modal__book-options__item-info[data-type="insurance"]');
                                    const insuranceCheckboxes = [];
                                    insuranceElements.forEach(element => {
                                        const parent = element.closest('.fleet-modal__book-options__item');
                                        const checkbox = parent.querySelector('.fleet-modal__book-options__item-tech input[type="checkbox"]');
                                        insuranceCheckboxes.push(checkbox);
                                    });

                                    insuranceCheckboxes.forEach((insuranceCheckbox) => {
                                        if (insuranceCheckbox !== checkbox && insuranceCheckbox.checked && !insuranceCheckbox.disabled) {
                                            insuranceCheckbox.checked = false;
                                        }
                                    });
                                }

                                sumEval();
                            });
                        });

                        // Add event listener to the modal places selects, to call sumEval()

                        // Add event listener to the modal dates select, to call sumEval()
                    }


                })
                .catch(error => {
                    console.error(error);
                    // ВНИМАНИЕ

                    // retryCount++;
                    // if (retryCount < 5) {
                    //     console.log(`Retrying request (${retryCount})...`);
                    //     fetchCarData();
                    // } else {
                    //     console.log('no luck ;(');
                    // }
                });
            }


            // put on the background fog
            fog.classList.add('fog_show');
            setTimeout(function() {
                fog.classList.add('fog_active');
            }, 6);
    
            // show the close button
            modalCloseButton.classList.add('fleet-modal__close_active');
    
            // show the modal window
            modal.classList.add('fleet-modal_active');
    
            // lock document scroll
            document.body.classList.add('scroll_lock');
    
            // listener for clicks outside of the menu
            setTimeout(function() {
                document.addEventListener('click', close);
            }, 500);
    
    
            // Put data inside
            // places
            $('#placePickupModal').val($('#placePickup').val()).trigger('change');
            $('#placeDropoffModal').val($('#placeDropoff').val()).trigger('change');
    
            // dates;
            if (datesPickerHeader.getStartDate() && datesPickerHeader.getEndDate()) {
                // if there are dates in the header inputs, put them in the modal inputs
                datesPickerModal.setStartDate(datesPickerHeader.getStartDate());
                datesPickerModal.setEndDate(datesPickerHeader.getEndDate());

                // activate the booking button
                document.querySelector('.fleet-modal__book-button').classList.remove('fleet-modal__book-button_disabled');
            }


            const modalBookingButton = document.querySelector('.fleet-modal__book-button');

            function onDateOrTimeChange() {
                // Stop the function if the booking button is disabled (that way we know that this fn was triggered on time select, without dates chosen)
                if (modalBookingButton.classList.contains('fleet-modal__book-button_disabled')) {
                    return;
                }

                // Place loading elements (decorative), to remove them before placing the data
                let optionsWrapper = document.querySelector('.fleet-modal__book-options__wrapper');
                optionsWrapper.innerHTML = '';
                for (let i = 0; i < 5; i++) {
                    optionsWrapper.insertAdjacentHTML('afterbegin', `
                        <div class="fleet-modal__book-options__item loading">
                    `);
                }

                // Get busy dates for the picked car, and lock them in the calendar. Then call fetchCarData()
                fetch(`./../php/carBusyDates.php?vehicle_id=${carId}&token=${token}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                    },
                })
                    .then(response => response.json())
                    .then(data => {

                        if ((data.status >= 300 && data.status <= 526) || data.status === 'error') {
                            // ВНИМАНИЕ display error message
    
                            return;
                        }

                        let dates = data.dates;
                        console.log("dates:", dates);

                        // easepick calendar lock days
                        if (dates.length > 0) {
                            const bookedDates = createDateArray(dates);

                            lockPlugin.options.filter = function(date, picked) {
                                return isDateInArray(bookedDates, date);
                            };
                            datesPickerModal.renderAll();
                        }

                        fetchCarData(dates);

                    })
                    .catch(error => {
                        console.log('error', error);
                        // ВНИМАНИЕ display error message
                    });
            }

            // Listen to dates select in modal
            datesPickerModal.on('select', function() {
                // Activate the booking button
                if (modalBookingButton.classList.contains('fleet-modal__book-button_disabled')) {
                    modalBookingButton.classList.remove('fleet-modal__book-button_disabled');
                }
                onDateOrTimeChange();
            });

            datesPickerModal.on('clear', function() {
                // if there was modalWaitTimeout running -- kill it and bring everything back to normal
                if (modalWaitTimeout) {
                    clearTimeout(modalWaitTimeout);
                    clearInterval(modalWaitCountdown);
                    
                    document.querySelector('.fleet-modal__book-options').classList.remove('fleet-modal__book-options_hide');
                    document.querySelector('.fleet-modal__book-wait').classList.remove('fleet-modal__book-wait_show');
                }

                // Clear the sum
                document.querySelector('.fleet-modal__book-total__sum').innerHTML = '€ 0';

                // Clear the deposit field
                document.querySelector('.fleet-modal__book-total__deposit').innerHTML = '';

                if (document.querySelector('.fleet-modal__book-options__item.delivery')) {
                    const allDeliveries = document.querySelectorAll('.fleet-modal__book-options__item.delivery');
                    allDeliveries.forEach((delivery) => {
                        delivery.remove();
                    });
                }

                if (document.querySelector('.fleet-modal__book-options__item.carSumCalc')) {
                    document.querySelector('.fleet-modal__book-options__item.carSumCalc').remove();
                }
            });

            $('#timePickupModal').on('change', onDateOrTimeChange);
            $('#timeDropoffModal').on('change', onDateOrTimeChange);



            // Listen to places select in modal
            function modalPlacePickupFunction(event) {
                if (datesPickerModal.getStartDate() && datesPickerModal.getEndDate()) {

                    // Place loading elements (decorative), to remove them before placing the data
                    let optionsWrapper = document.querySelector('.fleet-modal__book-options__wrapper');
                    optionsWrapper.innerHTML = '';
                    for (let i = 0; i < 5; i++) {
                        optionsWrapper.insertAdjacentHTML('afterbegin', `
                            <div class="fleet-modal__book-options__item loading">
                        `);
                    }

                    // Get busy dates for the picked car, and lock them in the calendar. Then call fetchCarData()
                    fetch(`./../php/carBusyDates.php?vehicle_id=${carId}&token=${token}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                        },
                    })
                        .then(response => response.json())
                        .then(data => {

                            if ((data.status >= 300 && data.status <= 526) || data.status === 'error') {
                                // ВНИМАНИЕ display error message
        
                                return;
                            }
        
                            let dates = data.dates;
                            console.log("dates:", dates);
        
                            // easepick calendar lock days
                            if (dates.length > 0) {
                                const bookedDates = createDateArray(dates);
        
                                lockPlugin.options.filter = function(date, picked) {
                                    return isDateInArray(bookedDates, date);
                                };
                                datesPickerModal.renderAll();
                            }
        
                            console.log('fetchCarData() 2');
                            fetchCarData(dates);
        
                        })
                        .catch(error => {
                            console.log('error', error);
                            // ВНИМАНИЕ display error message
                        });
                }
            }

            $('#placePickupModal').on('change', modalPlacePickupFunction);

        
            function modalPlaceDropoffFunction(event) {

                if (datesPickerModal.getStartDate() && datesPickerModal.getEndDate()) {

                    // Place loading elements (decorative), to remove them before placing the data
                    let optionsWrapper = document.querySelector('.fleet-modal__book-options__wrapper');
                    optionsWrapper.innerHTML = '';
                    for (let i = 0; i < 5; i++) {
                        optionsWrapper.insertAdjacentHTML('afterbegin', `
                            <div class="fleet-modal__book-options__item loading">
                        `);
                    }

                    // Get busy dates for the picked car, and lock them in the calendar. Then call fetchCarData()
                    fetch(`./../php/carBusyDates.php?vehicle_id=${carId}&token=${token}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                        },
                    })
                        .then(response => response.json())
                        .then(data => {

                            if ((data.status >= 300 && data.status <= 526) || data.status === 'error') {
                                // ВНИМАНИЕ display error message
        
                                return;
                            }
        
                            let dates = data.dates;
                            console.log("dates:", dates);
        
                            // easepick calendar lock days
                            if (dates.length > 0) {
                                const bookedDates = createDateArray(dates);
        
                                lockPlugin.options.filter = function(date, picked) {
                                    return isDateInArray(bookedDates, date);
                                };
                                datesPickerModal.renderAll();
                            }
        
                            console.log('fetchCarData() 3');
                            fetchCarData(dates);
        
                        })
                        .catch(error => {
                            console.log('error', error);
                            // ВНИМАНИЕ display error message
                        });
                }
            }

            $('#placeDropoffModal').on('change', modalPlaceDropoffFunction);


            modalButtonFunction = function(e) {
                e.preventDefault();
                // console.log('modal button clicked');

                // If button is deactivated, display alert (message) about dates and return
                if (modalBookingButton.classList.contains('fleet-modal__book-button_disabled')) {
                    document.querySelector('.fleet-modal__book-bottom__alert').classList.add('fleet-modal__book-bottom__alert_show');
                    setTimeout(function() {
                        document.querySelector('.fleet-modal__book-bottom__alert').classList.remove('fleet-modal__book-bottom__alert_show');
                    }, 5000);
                    return;
                }

                
                // If phone number is invalid, scroll to the field and display message
                if (!modalTelIti.isValidNumber()) {
                    document.querySelector('#modalTel').focus();
                    return;
                }


                // If button is activated and phone is valid, proceed to the initial booking, then adding extras and then booking confirmation

                // display loading elements
                modalBookingButton.textContent = langData.buttons.loading[pageLang];
                modalBookingButton.classList.add('fleet-modal__book-button_disabled');

                // get dates, times, and places ids and prepare them for a request
                let startDate = datesPickerModal.getStartDate(),
                    endDate = datesPickerModal.getEndDate();

                let timePickup = $('#timePickupModal').val(),
                    timeDropoff = $('#timeDropoffModal').val();
                let timePickupArray = timePickup.split(":");
                let timeDropoffArray = timeDropoff.split(":");
                let timePickupHours = parseInt(timePickupArray[0]);
                let timePickupMinutes = parseInt(timePickupArray[1]);
                let timeDropoffHours = parseInt(timeDropoffArray[0]);
                let timeDropoffMinutes = parseInt(timeDropoffArray[1]);
                
                startDate.setHours(timePickupHours, timePickupMinutes, 0, 0);
                endDate.setHours(timeDropoffHours, timeDropoffMinutes, 0, 0);

                let tzoffset = startDate.getTimezoneOffset() * 60000;
                let localISOTime = (new Date(startDate - tzoffset)).toISOString().slice(0, 19).replace('T', ' ');
                datePickup = localISOTime;
                let localISOTime2 = (new Date(endDate - tzoffset)).toISOString().slice(0, 19).replace('T', ' ');
                dateDropoff = localISOTime2;

                
                // remove ':00' seconds
                console.log("datePickup before split:", datePickup);
                console.log("dateDropoff before split:", dateDropoff);
                if ((datePickup.match(/:/g) || []).length === 2) {
                    datePickup = datePickup.slice(0, -3);
                }
                
                if ((dateDropoff.match(/:/g) || []).length === 2) {
                    dateDropoff = dateDropoff.slice(0, -3);
                }
                console.log("datePickup after split:", datePickup);
                console.log("dateDropoff after split:", dateDropoff);
                
                let dates = `${datePickup} - ${dateDropoff}`,
                    placePickup = $('#placePickupModal').val(),
                    placeDropoff = $('#placeDropoffModal').val();

                console.log('dates (on modal btn click):', dates);

                const email = document.querySelector('#modalContact').value,
                      tel = modalTelIti.getNumber(),
                      comments = document.querySelector('#modalComment').value;
                
                let url = `./../php/carBooking.php?dates=${dates}&pickup_location=${placePickup}&return_location=${placeDropoff}&token=${token}&vehicle_id=${carId}`;


                    // Send request
                    fetch(url, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                        }
                    })
                    .then(response => response.json())
                    .then(data => {

                        // if ((data.status >= 300 && data.status <= 526) || data.status === 'error') {
                        //     // ВНИМАНИЕ display error message
    
                        //     return;
                        // }

                        console.log('booking data:', data);

                        // Look for checked checkboxes, if their items data-type is insurance, write their data-id to variables
                        // Add insurance and options to request body
                        const checkboxes = document.querySelectorAll('.fleet-modal__book-options__item-tech input[type="checkbox"]');
                        let insuranceId;
                        const extrasIds = [];

                        checkboxes.forEach(checkbox => {
                            if (checkbox.checked) {
                                const itemInfo = checkbox.closest('.fleet-modal__book-options__item').querySelector('.fleet-modal__book-options__item-info');
                                const optionType = itemInfo.dataset.type;
                        
                                // add options
                                if (optionType === 'option') {
                                    const quantitySelect = checkbox.closest('.fleet-modal__book-options__item-tech').querySelector('.fleet-modal__book-options__item-tech__quantity');
                                    const quantity = quantitySelect ? parseInt($(`#optionQuantity${itemInfo.dataset.id}`).val()) : 1;
                                    extrasIds.push({ id: itemInfo.dataset.id, quantity });
                                }
                        
                                // add insurance
                                if (optionType === 'insurance' && !checkbox.disabled) {
                                    insuranceId = itemInfo.dataset.id;
                                } else if (optionType === 'insurance' && checkbox.disabled) {
                                    insuranceId = itemInfo.dataset.id;
                                }
                            }
                        });


                        // Send a mail to us -- if was already booked and returned 500

                        const placePickupSelect = $('#placePickupModal');
                        const placeDropoffSelect = $('#placeDropoffModal');
                        let insuranceLabel = "";
                        if (insuranceId) {
                            insuranceLabel = document.querySelector(`label[for="option${insuranceId}"]`).textContent;
                        }

                        const placePickup = {
                            id: placePickupSelect.val(),
                            text: placePickupSelect.select2('data')[0].text,
                        };

                        const placeDropoff = {
                            id: placeDropoffSelect.val(),
                            text: placeDropoffSelect.select2('data')[0].text,
                        };

                        let optionsData = [];
                        extrasIds.forEach(extra => {
                            const optionLabel = document.querySelector(`label[for="option${extra.id}"]`).textContent;
                            optionsData.push({ id: extra.id, quantity: extra.quantity, name: optionLabel });
                        });

                        if (data.status == 500) {

                            // Show fake success message with inquiry details
                            if (document.querySelector('.fleet-modal__book_active')) {
                                document.querySelector('.fleet-modal__book_active').classList.remove('fleet-modal__book_active');
                            }
                            document.querySelector('.fleet-modal__success').classList.add('fleet-modal__success_active');


                            let optionsDataForSuccess = '';
                            if (optionsData.length > 0) {
                                optionsDataForSuccess = '<ul>';
                                optionsData.forEach(option => {
                                    const quantity = option.quantity > 1 ? `x ${option.quantity}` : '';
                                    optionsDataForSuccess += `<li>&nbsp;&bull; ${option.name} ${quantity}</li>`;
                                });
                                optionsDataForSuccess += '</ul>';
                            }


                            const sOrderId = document.querySelector('#sOrderId'),
                                    sEmail = document.querySelector('#sEmail'),
                                    sTel = document.querySelector('#sTel'),
                                    sCarName = document.querySelector('#sCarName'),
                                    sDates = document.querySelector('#sDates'),
                                    sPlaces = document.querySelector('#sPlaces'),
                                    sInsurance = document.querySelector('#sInsurance'),
                                    sOptions = document.querySelector('#sOptions'),
                                    sComments = document.querySelector('#sComments');

                            sEmail.innerHTML = email;
                            sTel.innerHTML = `<span>${langData.buttons.phone[pageLang]}:</span> ` + tel;
                            sCarName.innerHTML = `<span>${brand} ${mark}</span>`;
                            sDates.innerHTML = `<span>${langData.buttons.dates[pageLang]}:</span> ` + dates;
                            sPlaces.innerHTML = `<span>${langData.buttons.places[pageLang]}:</span> ${placePickup.text} - ${placeDropoff.text}`;
                            sInsurance.innerHTML = `<span>${langData.buttons.insurance[pageLang]}:</span> ` + `${insuranceLabel}`;
                            sOptions.innerHTML = optionsDataForSuccess ? `<span>${langData.buttons.options[pageLang]}:</span> ` + optionsDataForSuccess : '';
                            sComments.innerHTML = `<span>${langData.buttons.comments[pageLang]}:</span> ` + (comments || '');

                            document.querySelector('.fleet-modal_active').scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });


                            // send mail to us
                            $.ajax({
                                url: "./../mail/php/mail.php",
                                type: "POST",
                                data: {
                                  "brand": brand,
                                  "mark": mark,
                                  "cardId": carId,
                                  "dates": dates,
                                  "placePickup": `${placePickup.text} (id: ${placePickup.id})`,
                                  "placeDropoff": `${placeDropoff.text} (id: ${placeDropoff.id})`,
                                  "tel": tel,
                                  "email": email,
                                  "comments": comments,
                                  "insuranceId": insuranceId,  
                                  "insuranceLabel": insuranceLabel,
                                  "optionsData": JSON.stringify(optionsData)
                                },
                                dataType: 'json',
                                success: function (res) {
                                    if (res.success) {
                                        console.log('Mail with the booking data was sent.');
                                    } else {
                                        console.log('Error: Mail with the booking data was not sent. Reason: ' + res.error);
                                    }
                                },
                                error: function (jqXHR, textStatus, errorThrown) {
                                    console.log('Error: AJAX request failed. ' + textStatus + ': ' + errorThrown);
                                }

                            });

                            // Put order data to JSON
                            // read the existing orders.json file
                            // let orders = JSON.parse(fs.readFileSync('../orders.json'));
                            // console.log("orders (json file):", orders);

                            // // get the number of orders that are already in the file
                            // let numOrders = orders.length;

                            // // generate a new numeric ID for the new order
                            // let orderId = numOrders + 1;


                            // // create the new order object
                            // let newOrder = {
                            //     "id": orderId,
                            //     "status": "Не забронировано: даты заняты",
                            //     "created": currentDate,
                            //     "car": `${brand} ${mark} (id: ${carId})`,
                            //     "dates": dates,
                            //     "places": `${placePickup.text} (id: ${placePickup.id}) - ${placeDropoff.text} (id: ${placeDropoff.id})`,
                            //     "email": email,
                            //     "tel": tel,
                            //     "comments": comments,
                            //     "insurance": `${insuranceLabel} (id: ${insuranceId})`,  
                            //     "optionsData": optionsDataString
                            // };

                            // // add the new order to the existing orders array
                            // orders.push(newOrder);

                            // // write the updated orders array back to the orders.json file
                            // fs.writeFileSync('../orders.json', JSON.stringify(orders));

                            return;
                        }

                        // // write current date and time CET
                        let currentDate = new Date().toLocaleString("en-US", {timeZone: "Europe/Berlin"});

                        console.log("optionsData:", optionsData);
                        let optionsDataProcessed = optionsData;
                        let optionsDataString = "";

                        if (optionsDataProcessed !== null) {
                            optionsDataString += "Опции:<br>";
                            for (let i = 0; i < optionsDataProcessed.length; i++) {
                                optionsDataString += optionsDataProcessed[i].name + ": " + optionsDataProcessed[i].value + "<br>";
                            }
                        }
                        

                        // If booking was successful, add extras and insurance
                        // Add the selected options and their quantities to postData
                        let postData = {
                            order_id: data.order_id,
                            token: token,
                            insurance: insuranceId
                        };
                        
                        extrasIds.forEach(extra => {
                            postData[`extras[${extra.id}]`] = extra.quantity;
                        });
                        postData['extras_count'] = extrasIds.length;
                        
                        console.log('postData:', postData);

                        fetch('./../php/addExtras.php', {
                            method: 'POST',
                            headers: {
                                'Authorization': 'Bearer ' + token,
                                'Accept': 'application/json',
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            body: new URLSearchParams(postData)
                        })
                        .then(response => response.json())
                        .then(data => {

                            if ((data.status >= 300 && data.status <= 526) || data.status === 'error') {
                                // ВНИМАНИЕ display error message
        
                                return;
                            }

                            console.log(data);
                            let sucOrderId = data.order_id;
                            
                            // ВНИМАНИЕ тут перед подтвержрением нужно будет сравнить total сумму с той, которая возвращает сервер. Если будет отличаться -- нужно будет об этом сообщить пользователю

                            fetch('./../php/confirmBooking.php', {
                                method: 'POST',
                                headers: {
                                    'Authorization': 'Bearer ' + token,
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/x-www-form-urlencoded'
                                },
                                body: new URLSearchParams({
                                    'order_id': data.order_id,
                                    'token': token,
                                    'tel': tel,
                                    'email': email,
                                    'comments': comments
                                })
                            })
                                .then(response => response.json())
                                .then(data => {
                                    console.log(data);
                                    if (data.status === 'success') {

                                        // Show success message with inquiry details
                                        if (document.querySelector('.fleet-modal__book_active')) {
                                            document.querySelector('.fleet-modal__book_active').classList.remove('fleet-modal__book_active');
                                        }
                                        document.querySelector('.fleet-modal__success').classList.add('fleet-modal__success_active');


                                        let optionsDataForSuccess = '';
                                        if (optionsData.length > 0) {
                                            optionsDataForSuccess = '<ul>';
                                            optionsData.forEach(option => {
                                                const quantity = option.quantity > 1 ? `x ${option.quantity}` : '';
                                                optionsDataForSuccess += `<li>&nbsp;&bull; ${option.name} ${quantity}</li>`;
                                            });
                                            optionsDataForSuccess += '</ul>';
                                        }


                                        const sOrderId = document.querySelector('#sOrderId'),
                                              sEmail = document.querySelector('#sEmail'),
                                              sTel = document.querySelector('#sTel'),
                                              sCarName = document.querySelector('#sCarName'),
                                              sDates = document.querySelector('#sDates'),
                                              sPlaces = document.querySelector('#sPlaces'),
                                              sInsurance = document.querySelector('#sInsurance'),
                                              sOptions = document.querySelector('#sOptions'),
                                              sComments = document.querySelector('#sComments');

                                        sEmail.innerHTML = email;
                                        sTel.innerHTML = `<span>${langData.buttons.phone[pageLang]}:</span> ` + tel;
                                        sOrderId.innerHTML = `<span>${langData.buttons.orderid[pageLang]}:</span> ` + sucOrderId;
                                        sCarName.innerHTML = `<span>${brand} ${mark}</span>`;
                                        sDates.innerHTML = `<span>${langData.buttons.dates[pageLang]}:</span> ` + dates;
                                        sPlaces.innerHTML = `<span>${langData.buttons.places[pageLang]}:</span> ${placePickup.text} - ${placeDropoff.text}`;
                                        sInsurance.innerHTML = `<span>${langData.buttons.insurance[pageLang]}:</span> ` + `${insuranceLabel}`;
                                        sOptions.innerHTML = optionsDataForSuccess ? `<span>${langData.buttons.options[pageLang]}:</span> ` + optionsDataForSuccess : '';
                                        sComments.innerHTML = `<span>${langData.buttons.comments[pageLang]}:</span> ` + (comments || '');


                                    } else if ((data.status >= 300 && data.status <= 526) || data.status === 'error') {
                                        // ВНИМАНИЕ выводить ошибку бы в идеале
                                        // может вернуть инфу в data.message -- можно выводить его пользователю, и скрывать поле ниже телефона
                                    }

                                    document.querySelector('.fleet-modal_active').scrollTo({
                                        top: 0,
                                        left: 0,
                                        behavior: 'smooth'
                                    });

                                })
                                .catch(error => {
                                    console.log('error', error);
                                    // ВНИМАНИЕ display error message
                                });
                            
                        })
                        .catch(error => {
                            console.log('error', error);
                            // ВНИМАНИЕ display error message
                        });
                    })
                    .catch(error => {
                        console.error(error);
                    });

            };

            // Modal booking button -- listen to clicks
            document.getElementById('fleetModalForm').addEventListener('submit', modalButtonFunction);



            // CAR TAB

            // Put data
            document.querySelector('.fleet-modal__title.car h2').innerHTML = 
            `
                ${brand} ${mark}
            `;

            let transName = langData.car.char[transmission][pageLang];

            document.querySelector('.fleet-modal__car').innerHTML = 
            `
                <div id="main-carousel" class="splide" role="group" aria-label="car images carousel">
                <div class="splide__track">
                    <ul class="splide__list">
                    </ul>
                </div>
                </div>
                <div id="thumbnail-carousel" class="splide" aria-label="A carousel with images of the car">
                    <div class="splide__track">
                        <ul class="splide__list">
                        </ul>
                    </div>
                </div>

                <div class="fleet-card__features">
                    <div class="fleet-card__features-item">${fuel}</div>
                    <div class="fleet-card__features-item">${transName} ${langData.car.designations.transmission[pageLang]}</div>
                    <div class="fleet-card__features-item">${engineVolume} ${engineText}</div>
                    <div class="fleet-card__features-item">${seats} ${langData.car.designations.seats[pageLang]}</div>
                </div>

                <div class="fleet-modal__car-text">${carText}</div>
            `;


            // Options
            // iterate through options, and so on you know what
            // const newElement = document.createElement('div');
            // newElement.classList.add('fleet-modal__book-options__item');
            // document.querySelector('.fleet-modal__book-options__wrapper').appendChild(newElement);

            setTimeout(function() {
                const carCarousels = document.querySelectorAll('.fleet-modal__car .splide__list');

                if (allPhotosQty == 1) {
                    carCarousels.forEach((item, index) => {
                        item.insertAdjacentHTML('beforeend', `
                            <li class="splide__slide"><img src="${allPhotos}" alt="the car's image"></li>
                        `);
                    });
                } else {
                    if (allPhotos.includes(',')) {
                        allPhotos = allPhotos.split(',');
                    }
                    
                    console.log("allPhotosQty:", allPhotosQty);
                    for (let i = 0; i < allPhotosQty; i++) {
                        carCarousels.forEach((item, index) => {
                            item.insertAdjacentHTML('beforeend', `
                                <li class="splide__slide"><img src="${allPhotos[i]}" alt="the car's image"></li>
                            `);
                        });
                    }
                }


                setTimeout(function() {
                    // Initialize image carousels
                    initializeCarousels();
                }, 300);
            }, 300);

        }
    
        // Fn for running some checks before calling for closing the modal window
        function close(e) {
            if (modal.classList.contains('fleet-modal_active') && e.target == fog) {
                closeModal();
            }
        }
    
        // Fn for closing the modal window
        function closeModal() {

            // if there was modalWaitTimeout running -- kill it and bring everything back to normal
            if (modalWaitTimeout) {
                clearTimeout(modalWaitTimeout);
                clearInterval(modalWaitCountdown);
                
                document.querySelector('.fleet-modal__book-options').classList.remove('fleet-modal__book-options_hide');
                document.querySelector('.fleet-modal__book-wait').classList.remove('fleet-modal__book-wait_show');
            }

            modalIsClosing = true;
            
            // remove the background fog
            fog.classList.remove('fog_active');
            setTimeout(function() {
                fog.classList.remove('fog_show');
            }, 400);
    
            // hide the close button
            modalCloseButton.classList.remove('fleet-modal__close_active');
    
            // hide the modal window
            modal.classList.remove('fleet-modal_active');
    
            // unlock document scroll
            document.body.classList.remove('scroll_lock');
    
            // remove listener for clicks outside of the menu
            document.removeEventListener('click', close);


            // Move chat button to the bottom of the screen
            if (window.innerWidth < 960) {
                document.querySelector('#chat').style.top = 'unset';
                document.querySelector('#chat').style.left = 'unset';
                document.querySelector('#chat').style.right = '1rem';
                document.querySelector('#chat').style.bottom = '1rem';
            }


            // Reset elements arrangement in modal window
            if (document.querySelector('.fleet-modal__title.car').classList.contains('fleet-modal__title_active')) {
                document.querySelector('.fleet-modal__title.car').classList.remove('fleet-modal__title_active');
            }
            if (document.querySelector('.fleet-modal__car').classList.contains('fleet-modal__car_active')) {
                document.querySelector('.fleet-modal__car').classList.remove('fleet-modal__car_active');
            }
            document.querySelector('.fleet-modal__title.book').classList.add('fleet-modal__title_active');
            document.querySelector('.fleet-modal__book').classList.add('fleet-modal__book_active');

            if (document.querySelector('.fleet-modal__success_active')) {
                document.querySelector('.fleet-modal__success_active').classList.remove('fleet-modal__success_active');

                const sOrderId = document.querySelector('#sOrderId'),
                      sCarName = document.querySelector('#sCarName'),
                      sDates = document.querySelector('#sDates'),
                      sPlaces = document.querySelector('#sPlaces'),
                      sInsurance = document.querySelector('#sInsurance'),
                      sOptions = document.querySelector('#sOptions'),
                      sComments = document.querySelector('#sComments');

                sOrderId.innerHTML = '';
                sCarName.innerHTML = '';
                sDates.innerHTML = '';
                sPlaces.innerHTML = '';
                sInsurance.innerHTML = '';
                sOptions.innerHTML = '';
                sComments.innerHTML = '';
            }


            // Remove options
            document.querySelector('.fleet-modal__book-options__wrapper').innerHTML = '';

            // Clear the comment section textarea
            document.querySelector('.fleet-modal__book-comment textarea').value = '';

            // Clear the sum
            document.querySelector('.fleet-modal__book-total__sum').innerHTML = '€ 0';

            // Clear the deposit field
            document.querySelector('.fleet-modal__book-total__deposit').innerHTML = '';


            // Remove listeners
            datesPickerModal.off('select');
            $('#placePickupModal').off('change', modalPlacePickupFunction);
            $('#placeDropoffModal').off('change', modalPlaceDropoffFunction);
            $('#placePickupModal').select2();
            $('#placeDropoffModal').select2();

            document.getElementById('fleetModalForm').removeEventListener('submit', modalButtonFunction);


            // The booking button
            document.querySelector('.fleet-modal__book-button').textContent = langData.buttons.book[pageLang];


            modalIsClosing = false;
        }

    }


    


    // MODAL WINDOW: INSIDE

    const modalCarSection = document.querySelector('.fleet-modal__car'),
          modalBookSection = document.querySelector('.fleet-modal__book'),
          modalCarSectionsButtons = document.querySelectorAll('.fleet-modal__title'),
          chevrons = document.querySelectorAll('.chevron');

        
    modalCarSectionsButtons.forEach(item => {
        item.addEventListener('click', e => {
            modalCarSection.classList.toggle('fleet-modal__car_active');
            modalBookSection.classList.toggle('fleet-modal__book_active');
            chevrons.forEach(item2 => {
                item2.classList.toggle('chevron_active');
            });
            modalCarSectionsButtons.forEach(item3 => {
                item3.classList.toggle('fleet-modal__title_active');
            });
        });
    });

    // Car images carousel
    async function initializeCarousels() {
        let main = await new Splide( '#main-carousel', {
            type       : 'fade',
            fixedHeight: 340,
            fixedWidth : '100%',
            // autoHeight: true,
            // autoWidth: true,
            pagination : false,
            arrows     : false,
            cover      : true,
            breakpoints: {
                1400: {
                    fixedHeight: 300,
                },
                720: {
                    fixedHeight: 280,
                },
                500: {
                    fixedHeight: 250,
                },
                490: {
                    fixedHeight: 230,
                },
            },
        });
    
        let thumbnails = await new Splide( '#thumbnail-carousel', {
            fixedWidth : 90,
            fixedHeight: 60,
            gap        : 10,
            rewind     : true,
            pagination : false,
            isNavigation: true,
            arrows: false,
            breakpoints: {
                959: {
                    fixedWidth : 60,
                    fixedHeight: 44,
                },
            },
        });
    
        main.sync( thumbnails );
        main.mount();
        thumbnails.mount();

    } 




    // SELECT BOXES INITIALIZATION

    // Places header
    $('#placePickup').select2({
        // dropdownCssClass: 'select2-dropdown-smooth-scroll'
        // dropdownParent: jQuery('.booking-form__place')
    });
    $('#placeDropoff').select2({

    });

    // $('#placePickup').on('select2:opening', function (e) {
    //     e.preventDefault();
    // });

    const selectBoxes = document.querySelectorAll('header .select2-selection--single');
    selectBoxes[0].style.borderRadius = '5px 0 0 5px';
    selectBoxes[1].style.borderRadius = '0 5px 5px 0';

    // this thing made ios safari jump. dont remember why i added it, gonna leave here jic
    // $(document).on('select2:open', () => {
    //     document.querySelector('.select2-search__field').focus();
    // });


    // Dates header
    function createEasepicker(elementStartId, elementEndId) {
        return new easepick.create({
            element: elementStartId,
            css: [
                "https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css",
                "../css/easepick.css",
            ],
            format: 'D MMMM YY',
            plugins: [RangePlugin, AmpPlugin, LockPlugin],
            RangePlugin: {
                elementEnd: elementEndId,
                tooltip: true, // Showing tooltip with how much days will be selected.
                tooltipNumber(num) {
                    return num - 1;
                },
                repick: true,
                // delimiter: '',
            },
            AmpPlugin: {
                // resetButton: true,
                resetButton: function() {
                    
                    if (!document.querySelector('.fleet-modal_active')) {
                        // if modal window is not open
                        
                        // show dummy fleet-cards for loading animation (fleetWrapper will be cleared)
                        showDummyFleetCards();
    
                        // show all company's availible cars again
                        fetch('./../php/rentGetCars.php?token='+token)
                            .then(response => response.json())
                            .then(data => {
                                if ((data.status >= 300 && data.status <= 526) || data.status === 'error') {
                                        // ВНИМАНИЕ display error message
                
                                        return;
                                }
                                generateFleetCards(data);
                            })
                            .catch(error => {
                                console.log('error', error);
                                // ВНИМАНИЕ display error message
                            });
    
                        // must return true so that the reset button works
                        return true;
                    } else {
                        // if modal window is open
                        console.log('clearing dates in modal easepick');
                        
                        // deactivate the booking button by giving it --light background
                        document.querySelector('.fleet-modal__book-button').classList.add('fleet-modal__book-button_disabled');

                        // must return true so that the reset button works
                        return true;
                    }
                },
                darkMode: true,
                // locale: {
                //     resetButton: `<a href="#" class="close"></a>`
                    // resetButton: `<div class="eraseIcon"></div>`
                // }
            },
            LockPlugin: {
                minDate: (function() {
                    const date = new Date();
                    date.setDate(date.getDate()); // было date.setDate(date.getDate() + 1); чтобы блокировать нынешний день
                    return date;
                })(),
                minDays: 2,
                maxDays: 365,
                // selectForward: true,
                presets: false, // Disable unallowed presets (when PresetPlugin is included).
                inseparable: true, // Disable date range selection with locked days.
                // filter: function(date, picked) {
                //     if (date.getFullYear() === 2023 && date.getMonth() === 3 && date.getDate() === 10) {
                //         return true;
                //     }
                //     return false;
                // }
            },
            setup(datePickupModal) {
                lockPlugin = datePickupModal.PluginManager.getInstance('LockPlugin');
            },
            // DateTime: {
            //     format: 'D MMM YY'
            // },
            zIndex: 10
        });
    }

    let datesPickerHeader;
    datesPickerHeader = createEasepicker(document.getElementById('datePickup'), document.getElementById('dateDropoff'));
    datesPickerHeader.clear();
    // datesPickerHeader.ui.container.dataset.theme = 'dark';
    

    setTimeout(() => {
        datesPickerHeader.on('select', function() {
            if (document.querySelector('.fleet .booking-wrapper .booking-form')) {
                generateFleetCardsOnDates();
            }
        });


        // Page themes: light or dark
        function setCalendarsTheme(theme) {
            datesPickerHeader.ui.container.dataset.theme = `${theme}`;
            datesPickerModal.ui.container.dataset.theme = `${theme}`;
        }

        // Read localstorage for theme preference to set for Easepick calendars
        const storedPreference = localStorage.getItem('theme');
        if (storedPreference) {
            const themePreference = JSON.parse(storedPreference);
            const timestamp = themePreference.timestamp;

            // Check if the stored theme preference is less than 3 hours old
            if (Date.now() - timestamp <= 3 * 60 * 60 * 1000) {
                const theme = themePreference.theme;

                if (theme === 'dark') {
                    setCalendarsTheme('dark');
                } else if (theme === 'light') {
                    setCalendarsTheme('light');
                }
            }
        }

        // MutationObserver for the themes. On change -- set Easepick calendars' theme
        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'class') {


                    let x = false;
                    if (document.documentElement.classList.contains("light-theme")) {
                        console.log('<html> light-theme detected');
                        setCalendarsTheme('light');
                        x = true;
                    } if (document.documentElement.classList.contains("dark-theme")) {
                        console.log('<html> dark-theme detected');
                        setCalendarsTheme('dark');
                        x = true;
                    } 
                    if (!x) {
                        // if theme is not specified in <html>
                        setTimeout(function() {
                            if (window.matchMedia('(prefers-color-scheme: light)').matches) {
                                console.log('preference for light-theme detected');
                                setCalendarsTheme('light');
                            } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                                console.log('preference for dark-theme detected');
                                setCalendarsTheme('dark');
                            }
                        }, 300);
                    }
                }
            }
        });
        
        // Start observing changes in the class attribute
        observer.observe(document.documentElement, { attributes: true });
    }, 500);


    // Places modal
    $('#placePickupModal').select2();
    $('#placeDropoffModal').select2();

    // Times modal
    $('#timePickupModal').select2();
    $('#timeDropoffModal').select2();

    // Dates modal
    // initiate it here but also reinitiate it in the openModal to pass locked dates
    datesPickerModal = createEasepicker(document.getElementById('datePickupModal'), document.getElementById('dateDropoffModal'));
    datesPickerModal.clear();

    setTimeout(() => {

        $('#placePickup').on('change', function (event) {
            if (document.querySelector('.fleet .booking-wrapper .booking-form')) {
                generateFleetCardsOnDates();
            }
        });
        $('#placeDropoff').on('change', function (event) {
            if (document.querySelector('.fleet .booking-wrapper .booking-form')) {
                generateFleetCardsOnDates();
            }
        });


        // POSITIONING CALENDAR IN HEADER
        const easepickWrapperHeader = document.querySelector('header .easepick-wrapper');
        const shadowRootHeader = easepickWrapperHeader.shadowRoot;
        const containerElementHeader = shadowRootHeader.querySelector('.container');

        const onEasepickHeaderEndOpen = () => {
            if (window.innerWidth <= 480) {
                containerElementHeader.style.setProperty('bottom', '15%', 'important');
            }
        };

        const onEasepickHeaderStartOpen = () => {
            if (window.innerWidth <= 480) {
                containerElementHeader.style.setProperty('bottom', '15%', 'important');
            }
        };

        // Attach the event listener to the calendars open
        const dateEndHeader = document.querySelector('#datePickup'),
              dateStartHeader = document.querySelector('#dateDropoff');
        dateEndHeader.addEventListener('click', onEasepickHeaderEndOpen);
        dateStartHeader.addEventListener('click', onEasepickHeaderStartOpen);


        // POSITIONING CALENDAR IN MODAL WINDOW
        const easepickWrapperModal = document.querySelector('.fleet-modal__book .easepick-wrapper');
        const shadowRootModal = easepickWrapperModal.shadowRoot;
        const containerElementModal = shadowRootModal.querySelector('.container');

        const onEasepickModalEndOpen = () => {
            const left = containerElementModal.style.left;
          
            if (parseInt(left) === 0) {
              // Start calendar opened
            } else if (window.innerWidth > 480) {
                // End calendar opened
                containerElementModal.style.setProperty('left', '118px', 'important');
            } else if (window.innerWidth <= 480) {
                containerElementModal.style.setProperty('top', '222px', 'important');
            }

            containerElementModal.style.setProperty('height', 'fit-content', 'important');
        };

        const onEasepickModalStartOpen = () => {
            if (window.innerWidth <= 480) {
                containerElementModal.style.setProperty('top', '222px', 'important');
            }

            containerElementModal.style.setProperty('height', 'fit-content', 'important');
        };

        // Attach the event listener to the calendars open
        const dateEndModal = document.querySelector('#dateDropoffModal'),
              dateStartModal = document.querySelector('#datePickupModal');
        dateEndModal.addEventListener('click', onEasepickModalEndOpen);
        dateStartModal.addEventListener('click', onEasepickModalStartOpen);
    }, 500);

    

    // MOVE HEADER FORM on click

    const headerFormButton = document.querySelector('.booking-form__button'),
          headerForm = document.querySelector('.header-main .booking-form'),
          headerWrapper = document.querySelector('header .booking-wrapper'),
          fleetWrapper = document.querySelector('.fleet .booking-wrapper');


    headerFormButton.addEventListener('click', (e) => {

        generateFleetCardsOnDates = function() {

            // show dummy fleet-cards for loading animation (fleetWrapper will be cleared)
            showDummyFleetCards();


            let startDate = datesPickerHeader.getStartDate(),
                endDate = datesPickerHeader.getEndDate();
            // Set times to 00:00
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);

    
            if (startDate && endDate) {
    
                // Filter the fleet cards
                let datePickup = startDate.toISOString().slice(0, 19).replace('T', ' '),
                    dateDropoff = endDate.toISOString().slice(0, 19).replace('T', ' '),
                    placePickup = $('#placePickup').val(),
                    placeDropoff = $('#placeDropoff').val();

                    
                // remove ':00' seconds
                if ((datePickup.match(/:/g) || []).length === 2) {
                    datePickup = datePickup.slice(0, -3);
                }
                
                if ((dateDropoff.match(/:/g) || []).length === 2) {
                    dateDropoff = dateDropoff.slice(0, -3);
                }
                    
                let dates = encodeURIComponent(`${datePickup} - ${dateDropoff}`);
                console.log("datePickup:", datePickup);
                console.log("dateDropoff:", dateDropoff);
                console.log("dates (showCarsByDates):", dates);
    
                let url = `./../php/showCarsByDates.php?dates=${dates}&pickup_location=${placePickup}&return_location=${placeDropoff}&token=${token}`;
    
                fetch(url, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                    },
                })
                    .then(response => response.json())
                    .then(data => {

                        if ((data.status >= 300 && data.status <= 526) || data.status === 'error') {
                            // ВНИМАНИЕ display error message

                            console.log('Error from 300 to 526, or just "error", data:', data);
    
                            return;
                        }
                
                        console.log(data.vehicles);

                        if (data.vehicles.length > 0) {
                            generateFleetCards(data.vehicles);
                        } else {
                            console.log('no cars availible, showing empty message');
                            document.querySelector('.fleet-empty').classList.add('fleet-empty_active');

                            // hide dummy cards with loading animation
                            if (document.querySelector('.fleet-dummy').classList.contains('fleet-dummy_active')) {
                                const dummyWrappers = document.querySelectorAll('.fleet-dummy');
                                for (let i = 0; i < dummyWrappers.length; i++) {
                                dummyWrappers[i].classList.remove('fleet-dummy_active');
                                }
                            }
                        }
                
                    })
                    .catch(error => {
                        console.log('error', error);
                        // ВНИМАНИЕ display error message
                    });
    
    
                // Fly down
                // version 2, simple
                if (!document.querySelector('.fleet .booking-wrapper .booking-form')) {
                    e.preventDefault();
                    headerForm.classList.add('booking-form_hide');
                    // headerWrapper.style.height = '0';
                    fleetWrapper.style.height = '91px';
                    
                    setTimeout(function() {
                        document.getElementById("fleet").scrollIntoView();
                        setTimeout(function() {
                            headerWrapper.style.margin = '0';
                            headerFormButton.classList.toggle('hide');
                            fleetWrapper.appendChild(headerForm);
                            setTimeout(function() {
                                headerForm.classList.remove('booking-form_hide');
                            }, 200);
                        }, 400);
                    }, 1000);
                }


                // count cards and append invisible if needed
    
            } else {
                e.preventDefault();
                document.querySelector('.booking-form__dates-alert').classList.add('booking-form__dates-alert_show');
                setTimeout(function() {
                    document.querySelector('.booking-form__dates-alert').classList.remove('booking-form__dates-alert_show');
                }, 5000);
            }

        };
        generateFleetCardsOnDates();

    });



    // FLEET
    let option1, option2, option3, option4, option5;

    // Filters initialize
    const selectBoxesFleet = document.querySelectorAll('.fleet-filters .select2-selection--single'); // ВНИМАНИЕ не достаёт
    const filtersItem = document.querySelectorAll('.fleet-filters__item');
    selectBoxesFleet.forEach((item) => {
        item.style.borderRadius = '30px';
    });

    function fleetFiltersInitialize() {
        $('.fleet-filters__item.1').select2({
            placeholder: `${langData.car.char.transmission[pageLang]}`,
            allowClear: true,
            minimumResultsForSearch: Infinity,
            scrollAfterSelect: false
        });
        // $('.fleet-filters__item.2').select2({
        //     placeholder: `${langData.car.char.year[pageLang]}`,
        //     allowClear: true,
        //     minimumResultsForSearch: Infinity,
        // });
        $('.fleet-filters__item.3').select2({
            placeholder: `${langData.car.char.seats[pageLang]}`,
            allowClear: true,
            minimumResultsForSearch: Infinity,
        });
        // $('.fleet-filters__item.4').select2({
        //     placeholder: `${langData.car.char.luggageCapacity[pageLang]}`,
        //     allowClear: true,
        //     minimumResultsForSearch: Infinity,
        // });
        // $('.fleet-filters__item.5').select2({
        //     placeholder: `${langData.car.char.sensors[pageLang]}`,
        //     allowClear: true,
        //     minimumResultsForSearch: Infinity,
        // });
    }
    fleetFiltersInitialize();

    // $('.fleet-filters__item.1').on('select2:unselecting', function (event) {
    //     event.preventDefault();
    //     $('.fleet-filters__item.1').val(null).trigger('change');
    // });
    // $('.fleet-filters__item.2').on('select2:unselecting', function (event) {
    //     event.preventDefault();
    //     $('.fleet-filters__item.2').val(null).trigger('change');
    // });
    // $('.fleet-filters__item.3').on('select2:unselecting', function (event) {
    //     event.preventDefault();
    //     $('.fleet-filters__item.3').val(null).trigger('change');
    // });
    // $('.fleet-filters__item.4').on('select2:unselecting', function (event) {
    //     event.preventDefault();
    //     $('.fleet-filters__item.4').val(null).trigger('change');
    // });
    // $('.fleet-filters__item.5').on('select2:unselecting', function (event) {
    //     event.preventDefault();
    //     $('.fleet-filters__item.5').val(null).trigger('change');
    // });


    // Filter items listen to change
    $('.fleet-filters__item.1').on('change', function (event) {
        option1 = $('.fleet-filters__item.1').val();
        filterCardsWithOptions();
        // Reinitialize select2's because appearance breaks
        fleetFiltersResize();
    });
    // $('.fleet-filters__item.2').on('change', function (event) {
    //     option2 = $('.fleet-filters__item.2').val();
    //     filterCardsWithOptions();
    //     // Reinitialize select2's because appearance breaks
    //     fleetFiltersResize();
    // });
    $('.fleet-filters__item.3').on('change', function (event) {
        option3 = $('.fleet-filters__item.3').val();
        filterCardsWithOptions();
        // Reinitialize select2's because appearance breaks
        fleetFiltersResize();
    });
    // $('.fleet-filters__item.4').on('change', function (event) {
    //     option4 = $('.fleet-filters__item.4').val();
    //     filterCardsWithOptions();
    //     // Reinitialize select2's because appearance breaks
    //     fleetFiltersResize();
    // });
    // $('.fleet-filters__item.5').on('change', function (event) {
    //     option5 = $('.fleet-filters__item.5').val();
    //     filterCardsWithOptions();
    //     // Reinitialize select2's because appearance breaks
    //     fleetFiltersResize();
    // });


    function filterCardsWithOptions() {

        // Bring dummies on stage
        if (!document.querySelector('.fleet-dummy_active')) {
            showDummyFleetCards('hide');
        }


        // Filter fleet-cards if there are filters selected
        let promises = [];

        // Loop through each fleet card element and check if it should be shown or hidden according to the selected filter values
        document.querySelectorAll('.fleet-card').forEach((cardElement) => {
            if (!cardElement.classList.contains('dummy')) {
                promises.push(new Promise(resolve => {
                    if (!cardElement.classList.contains('fleet-card_invisible')) {
                        const cardYear = cardElement.querySelector('.fleet-card__info').getAttribute('data-year');
                        const cardTransmission = cardElement.querySelector('.fleet-card__info').getAttribute('data-transmission');
                        const cardSeats = cardElement.querySelector('.fleet-card__info').getAttribute('data-seats');
                        const cardLuggage = cardElement.querySelector('.fleet-card__info').getAttribute('data-largebags');
                        const cardSensors = cardElement.querySelector('.fleet-card__info').getAttribute('data-option2');
                    
                        // Check if the card matches the selected options
                        const isTransmissionMatch = !option1 || option1 === cardTransmission;
                        const isYearMatch = !option2 || parseInt(option2) <= parseInt(cardYear);
                        const isSeatsMatch = !option3 || parseInt(option3) <= parseInt(cardSeats);
                        const isLuggageMatch = !option4 || parseInt(option4) <= parseInt(cardLuggage);
                        const isSensorsMatch = !option5 || option5 == cardSensors;
                    
                        // Show or hide the card based on whether it matches the selected filter value
                        cardElement.classList.toggle('fleet-card_hide', !(isTransmissionMatch && isYearMatch && isSeatsMatch && isLuggageMatch && isSensorsMatch));
                        // cardElement.style.display = isTransmissionMatch && isYearMatch ? 'block' : 'none';
                    }
                    resolve();
                }));

                Promise.all(promises).then(() => {
                    // rearrange fleet cards when filtering finished
                    fleetWrapperRearrange();
                });
            }
        });
    }
      


    // On window resize, rearrange the fleet filter inputs
    window.addEventListener('resize', onResize);
    // window.addEventListener('orientationchange', onResize);

    function onResize() {
        fleetFiltersResize();
        setTimeout(fleetWrapperRearrange, 300);

        if (document.querySelector('.fleet-modal_active')) {
            renderDecorDots();
        }
    }

    function fleetFiltersResize() {
        setTimeout(function() {
            for (let i = 1; i <= filtersItem.length; i++) {
              $(`.fleet-filters__item.${i}`).select2('destroy');
            }

            fleetFiltersInitialize();
        }, 300);
    }    

    // Fn for rearranging fleet cards OR showing empty message
    function fleetWrapperRearrange() {

        // if there are empty cards, remove them
        if (document.querySelector('.fleet-card_invisible')) {
            const fleetCardsInvisible = document.querySelectorAll('.fleet-card_invisible');
            fleetCardsInvisible.forEach((item) => {
                item.remove();
            });
        }


        // if empty message is shown, remove it
        if (document.querySelector('.fleet-empty_active')) {
            document.querySelector('.fleet-empty_active').classList.remove('fleet-empty_active');
        }

        // if error message is shown, remove it
        // if (document.querySelector('.fleet-error_active')) {
        //     document.querySelector('.fleet-error_active').classList.remove('fleet-error_active');
        // }


        // if fleet-wrapper doesn't have any cards, show empty message
        const fleetWrapperCards = document.querySelector('.fleet-wrapper').children;
        const allCardsHidden = Array.prototype.every.call(fleetWrapperCards, (card) => {
            return card.classList.contains('fleet-card_hide');
        });
        
        if (!allCardsHidden) {
            // If wrapper not empty, rearrange cards
            const fleetCards = Array.from(document.querySelectorAll('.fleet-card'));
            const visibleFleetCards = fleetCards.filter(card => 
                !card.classList.contains('fleet-card_hide') && 
                !card.classList.contains('dummy') &&
                !card.classList.contains('fleet-card_invisible')
            );
            const fleetCount = visibleFleetCards.length;
    
            let remainder,
                elementsToAdd;
    
                
            let mediaQuery = window.matchMedia('(max-width: 719px)');
            if (mediaQuery.matches) {
                remainder = 0;
            }
    
            mediaQuery = window.matchMedia('(min-width: 720px) and (max-width: 1199px)');
            if (mediaQuery.matches) {
                remainder = fleetCount % 2;        
            }
    
            mediaQuery = window.matchMedia('(min-width: 1200px)');
            if (mediaQuery.matches) {
                remainder = fleetCount % 3;
            }
    
    
            if (remainder !== 0) {
                elementsToAdd = 3 - remainder;
                for (let i = 0; i < elementsToAdd; i++) {
                    const newElement = document.createElement('div');
                    newElement.classList.add('fleet-card', 'fleet-card_invisible');
                    document.querySelector('.fleet-wrapper').appendChild(newElement);
                }
            }
        } else if (allCardsHidden) {
            // If wrapper empty, show empty message
            document.querySelector('.fleet-empty').classList.add('fleet-empty_active');
        }


        // Show fleetWrapper again if it was hidden
        // and Hide dummyWrapper if if (they) was(were) shown
        let checkDummiesShown = function() {
            if (dummiesShown) {
                if (!document.querySelector('.fleet-wrapper').classList.contains('fleet-wrapper_active')) {
                    document.querySelector('.fleet-wrapper').classList.add('fleet-wrapper_active');
                }
            
                if (document.querySelector('.fleet-dummy').classList.contains('fleet-dummy_active')) {
                    const dummyWrappers = document.querySelectorAll('.fleet-dummy');
                    for (let i = 0; i < dummyWrappers.length; i++) {
                    dummyWrappers[i].classList.remove('fleet-dummy_active');
                    }
                }
            } else {
                setTimeout(checkDummiesShown, 50);
            }
        };
        checkDummiesShown();

    }




    function sumEval() {
        // Get the total HTML element
        const totalElement = document.querySelector('.fleet-modal__book-total__sum');
    
        // Set car total and amount of days to calculate options sums and add to total
        let total = 0,
            days;
        if (document.querySelector('.fleet-modal__book-button').classList.contains('fleet-modal__book-button_disabled')) {
            days = 1;
        } else {
            total = carSum;
            // get amount of days
            let date1 = datesPickerModal.getStartDate(),
                date2 = datesPickerModal.getEndDate();
            let timePickup = $('#timePickupModal').val(),
                timeDropoff = $('#timeDropoffModal').val();
                
            date1.setHours(Number(timePickup.split(':')[0]), Number(timePickup.split(':')[1]), 0, 0);
            date2.setHours(Number(timeDropoff.split(':')[0]), Number(timeDropoff.split(':')[1]));
            
            console.log("date1:", date1);
            console.log("date2:", date2);

            let difference = date2.getTime() - date1.getTime();
            let hoursDifference = difference / (1000 * 60 * 60);
            days = Math.ceil(hoursDifference / 24);
            // days = difference / (1000 * 60 * 60 * 24);
            // days = Math.round(days);

            console.log('days calculated: ', days);
        }

        // Add the price of each checked option to the total
        document.querySelectorAll('.fleet-modal__book-options__item input[type="checkbox"]:checked').forEach((checkbox) => {
            const dataElement = checkbox.closest('.fleet-modal__book-options__item').querySelector('.fleet-modal__book-options__item-info');
            const id = dataElement.dataset.id;
            const price = Number(dataElement.dataset.price);
            const counter = dataElement.dataset.counter;
            const type = dataElement.dataset.type;

            if (type != 'delivery' && type != 'carSumCalc') {
                // Determine quantity
                const quantitySelect = checkbox.closest('.fleet-modal__book-options__item-tech').querySelector('.fleet-modal__book-options__item-tech__quantity');
                let quantity = quantitySelect ? parseInt($(`#optionQuantity${id}`).val()) : 1;

                if (counter === 'single') {
                    total += price * quantity;
                } else if (counter === 'multiple') {
                    total += price * quantity * days;
                }
            }
        });


        if (datesPickerModal.getStartDate() && datesPickerModal.getEndDate()) {
            if (!document.querySelector(`.fleet-modal__book-options__item.carSumCalc`)) {

                let daysText;
                if (days == 1) {
                    daysText = langData.buttons.day[pageLang];
                } else {
                    daysText = langData.buttons.days[pageLang];
                }

                // CAR SUM CALCULATION
                let carSumTotal = carSum - deliveryTotal;
                let carPrice = Number.isInteger(carSumTotal / days) ? (carSumTotal / days).toString() : (carSumTotal / days).toFixed(2);
                console.log("carSumTotal:", carSumTotal);
                console.log("carPrice:", carPrice);
                let optionsWrapper = document.querySelector('.fleet-modal__book-options__wrapper');
                optionsWrapper.insertAdjacentHTML('beforeend', `
                    <div class="fleet-modal__book-options__item compulsory carSumCalc">
                        <div class="fleet-modal__book-options__item-tech">
                            <input id="carSumCalc" type="checkbox" checked="true" disabled>
                            <label for="carSumCalc">${days} ${daysText} x €${carPrice}</label>
                        </div>
                        <div class="fleet-modal__book-options__item-dots"></div>
                        <span class="fleet-modal__book-options__item-price">€${carSumTotal}</span>
                        <div class="fleet-modal__book-options__item-info" 
                            data-price="${carSumTotal}"
                            data-counter="single"
                            data-type="carSumCalc"
                        ></div>
                    </div>
                `);
    
                renderDecorDots();
            }
        }

    
        // Update the total element
        totalElement.textContent = `€${total}`;
    }


    function renderDecorDots() {
        setTimeout(function() {
            console.log('Rendering decorative dots.');
            // Put decorative dots between options lables and prices
            const optionsItems = document.querySelectorAll('.fleet-modal__book-options__item');
            
            optionsItems.forEach((item, index) => {
                const wrapperWidth = document.querySelector('.fleet-modal__book-options__wrapper').offsetWidth;
                const techWidth = item.querySelector('.fleet-modal__book-options__item-tech').offsetWidth;
                const priceWidth = item.querySelector('.fleet-modal__book-options__item-price').offsetWidth;

                item.querySelector('.fleet-modal__book-options__item-dots').style.width = wrapperWidth - techWidth - priceWidth - 3 + 'px';
                item.querySelector('.fleet-modal__book-options__item-dots').style.left = techWidth + 2 + 'px';
            });
        }, 300);
    }




    // FILTER THE CARDS

    const filterElements = document.querySelectorAll('.fleet-filters__item');
    const fleetCardElements = document.querySelectorAll('.fleet-card');

    filterElements.forEach((item) => {
        item.addEventListener('change', () => {
            // Get the selected filter value
            const filterValue = item.value;
            // console.log("filterValue", filterValue);

            // Loop through each fleet card element
            document.querySelectorAll('.fleet-card').forEach((cardElement) => {
                if (!cardElement.classList.contains('dummy')) {
                    // Get the feature item elements in this card
                    const featureElements = cardElement.querySelectorAll('.fleet-card__features-item');

                    // Check if any of the feature item elements contains the selected filter value
                    const match = [...featureElements].some((element) => {
                        return element.value.includes(filterValue);
                    });

                    // Show or hide the card based on whether it matches the selected filter value
                    cardElement.classList.toggle('fleet-card_hide', !(isTransmissionMatch && isYearMatch));
                    // cardElement.style.display = match ? 'block' : 'none';
                }
            });
        });
    });


    // Functions for easepick functonality
    const createDateArray = (array) => {
        return array.map(d => {
          if (d.from && d.to) {
            // Parse date strings in ISO format to ensure compatibility with Safari and Android
            const start = new Date(d.from.replace(/-/g, '/'));
            const end = new Date(d.to.replace(/-/g, '/'));
            
            return [start, end];
          }
            
          // Parse single date string in ISO format to ensure compatibility with Safari and Android
          return new Date(d.from || d.to).replace(/-/g, '/');
        });
    };

    // Array.prototype.inArray = function(value) {
    //     for (let i = 0; i < this.length; i++) {
    //       if (this[i] === value) {
    //         return true;
    //       }
    //     }
    //     return false;
    // };

    function isDateInArray(dates, date) {
        for (let i = 0; i < dates.length; i++) {
            let d = dates[i];
            if (Array.isArray(d)) {
                let start = new Date(d[0].getFullYear(), d[0].getMonth(), d[0].getDate());
                let end = new Date(d[1].getFullYear(), d[1].getMonth(), d[1].getDate());
                let selectedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                if (selectedDate >= start && selectedDate <= end) {
                    return true;
                }
            } else {
                let lockedDate = new Date(d.getFullYear(), d.getMonth(), d.getDate());
                let selectedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
                if (selectedDate === lockedDate) {
                    return true;
                }
            }
        }
        return false;
    }
      


    // A simple timer
    function startCountdown(secondsAmount, element) {
        let totalTimeInSeconds = secondsAmount;
        let interval;
    
        function updateTimer() {
            let minutes = Math.floor(totalTimeInSeconds / 60);
            let seconds = totalTimeInSeconds % 60;
    
            element.textContent = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
            totalTimeInSeconds--;
    
            if (totalTimeInSeconds < 0) {
                clearInterval(interval);
                // You can add any additional code here to execute when the timer reaches 0
            }
        }
    
        updateTimer(); // This will display the initial time
        interval = setInterval(updateTimer, 1000); // Update the timer every second
    
        // Return the interval ID so that it can be cleared later
        return interval;
    }    


    // telephone number input
    modalTel = document.querySelector("#modalTel");
    intlTelInput(modalTel, {
        autoPlaceholder: "aggressive",
        preferredCountries: ['me', 'rs', 'ba', 'ua', 'ru', 'tr', 'de', 'gb'],
        utilsScript: false, // disable loading the utils script again
        utils: utils, // pass the reference of the utils object
    });

    setTimeout(() => {
        const telValidationCircle = document.querySelector('.tel-validation-circle');

        modalTel.addEventListener("open:countrydropdown", function () {
            document.querySelector('.iti--allow-dropdown').style.zIndex = 1;
            telValidationCircle.classList.remove('invalid');
            telValidationCircle.classList.remove('valid');
        });
        modalTel.addEventListener("close:countrydropdown", function () {
            document.querySelector('.iti--allow-dropdown').style.zIndex = 0;
        });


        modalTelIti = window.intlTelInputGlobals.getInstance(modalTel);

        modalTel.addEventListener("blur", () => {
            const enteredNumber = modalTelIti.getNumber();
            const isValid = modalTelIti.isValidNumber();

            modalTelIti.setNumber(enteredNumber);
          
            if (isValid) {
                telValidationCircle.classList.remove('invalid');
                telValidationCircle.classList.add('valid');
            } else {
                telValidationCircle.classList.remove('valid');
                telValidationCircle.classList.add('invalid');
            }
        });
    }, 1500);


    // Year in the bottom of the page
    const yearElement = document.querySelector('.footer-wrapper__item.year');
    const currentYear = new Date().getFullYear();
    // Append the current year to the existing content
    yearElement.innerHTML += `-${currentYear}`;


    } // end of the main function

}