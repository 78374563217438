var $ = require("jquery");
import header from './header';
import points from './points';
import fleet from './fleet';
import steps from './steps';
import faq from './faq';
import footer from './footer';

window.addEventListener('DOMContentLoaded', (event) => {

    header();
    points();
    fleet();
    // steps();
    faq();
    footer();
    
});
