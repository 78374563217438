import e from "cors";

export default function() {

    const burgir = document.querySelector('.header-upper__burgir'),
          burgirLines = document.querySelectorAll('.header-upper__burgir-line'),
          menu = document.querySelector('.header-upper__menu'),
          menuItem = document.querySelector('.header-upper__menu-item'),
          fog = document.querySelector('.fog');
    let menuClicked = false;




    // PAGE MENU

    function closeMenu() {
        // burgir lines styles
        burgirLines[0].classList.remove('header-upper__burgir-line-1_active');
        burgirLines[1].classList.remove('header-upper__burgir-line-2_active');
        burgirLines[2].classList.remove('header-upper__burgir-line-3_active');

        // remove the background fog
        fog.classList.remove('fog_active');
        setTimeout(function() {
            fog.classList.remove('fog_show');
        }, 400);

        // hide the menu
        menu.classList.remove('header-upper__menu_active');

        // unlock document scroll
        document.body.classList.remove('scroll_lock');

        menuClicked = false;
        document.removeEventListener('click', checkBeforeClosingMainMenu);
    }

    function checkBeforeClosingMainMenu(e) {
        if (menu.classList.contains('header-upper__menu_active') && e.target != menu) {
            closeMenu();
        }
    }

    burgir.addEventListener('click', e => {
        if (!menuClicked) {
            // burgir lines styles
            burgirLines[0].classList.add('header-upper__burgir-line-1_active');
            burgirLines[1].classList.add('header-upper__burgir-line-2_active');
            burgirLines[2].classList.add('header-upper__burgir-line-3_active');

            // put on the background fog
            fog.classList.add('fog_show');
            setTimeout(function() {
                fog.classList.add('fog_active');
            }, 6);

            // show the menu
            menu.classList.add('header-upper__menu_active');

            // lock document scroll
            document.body.classList.add('scroll_lock');
            
            // listener for clicks outside of the menu
            setTimeout(function() {
                document.addEventListener('click', checkBeforeClosingMainMenu);
                menuClicked = true;
            }, 500);
        } else {
            closeMenu();
        }
    });




    // SETTINGS MENU

    const setsButton = document.querySelector('.header-upper__settings-lang'),
          setsMenu = document.querySelector('.header-upper__settings-menu');
    let setsMenuClicked = false;


    function closeSetsMenu() {

        // remove the background fog
        fog.classList.remove('fog_active');
        setTimeout(function() {
            fog.classList.remove('fog_show');
        }, 400);

        // hide the menu
        setsMenu.classList.remove('header-upper__settings-menu_active');

        // unlock document scroll
        document.body.classList.remove('scroll_lock');

        setsMenuClicked = false;
        document.removeEventListener('click', checkBeforeClosingSetsMenu);
    }

    function checkBeforeClosingSetsMenu(e) {
        if (setsMenu.classList.contains('header-upper__settings-menu_active') && e.target != setsMenu) {
            closeSetsMenu();
        }
    }
        
    setsButton.addEventListener('click', e => {
        if (!setsMenuClicked) {

            // put on the background fog
            const mediaQuery = window.matchMedia("(max-width: 959px)");
            if (mediaQuery.matches) {
                // show fog
                fog.classList.add('fog_show');
                setTimeout(function() {
                    fog.classList.add('fog_active');

                }, 6);

                // lock document scroll
                document.body.classList.add('scroll_lock');
            }

            // show the menu
            setsMenu.classList.add('header-upper__settings-menu_active');

            
            // listener for clicks outside of the menu
            setTimeout(function() {
                document.addEventListener('click', checkBeforeClosingSetsMenu);
                setsMenuClicked = true;
            }, 500);
        } else {
            closeSetsMenu();
        }
    });



    // TIP

    const tipBlock = document.querySelector('.header-tip');
    const tipIcon = document.querySelector('.header-tip__icon');
    const tipTexts = document.querySelectorAll('.header-tip__text');
    let tipClicked = false;

    function tipShowElements() {
        tipBlock.classList.add('header-tip_active');
        tipTexts.forEach(item => {
            item.classList.add('header-tip__text_active');
        });
    }

    function tipHideElements(e) {
    if (!tipBlock.contains(e.relatedTarget)) {
        tipBlock.classList.remove('header-tip_active');
        tipTexts.forEach(item => {
            item.classList.remove('header-tip__text_active');
        });
    }
    }

    tipBlock.addEventListener('mouseover', e => {
    if (e.target === tipIcon && !tipClicked) {
        tipShowElements();
        tipBlock.addEventListener('mouseout', tipHideElements);
    }
    });

    tipBlock.addEventListener('click', e => {
    if (e.target === tipIcon) {
        tipClicked = true;
        tipBlock.removeEventListener('mouseout', tipHideElements);
        tipShowElements();
        tipIcon.style.cursor = 'default';
    }
    });

    // Stop event propagation on the tipText element
    // tipText.addEventListener('mouseover', e => {
    //     e.stopPropagation();
    // });
    tipTexts.forEach(item => {
        item.addEventListener('mouseover', e => {
            e.stopPropagation();
        });
    });




    // CHAT BUTTON
    // chatIcon = document.querySelector('chat__icon');
    // chatIconsImages = ['../icons/instagram.svg', '../icons/telegram.svg', '../icons/viber.svg', '../icons/whatsapp.svg'];

    // setInterval(function() {
    //     chatIcon.style.backgroundImage = 'url(../icons/instagram.svg)';
    // }, 500);



    // THEME LIGHT-DARK
    const themeButton = document.querySelectorAll('.header-upper__theme');

    // Read localstorage for theme preference
    const storedPreference = localStorage.getItem('theme');
    if (storedPreference) {
        const themePreference = JSON.parse(storedPreference);
        const timestamp = themePreference.timestamp;

        // Check if the stored theme preference is less than 3 hours old
        if (Date.now() - timestamp <= 3 * 60 * 60 * 1000) {
            const theme = themePreference.theme;

            if (theme === 'dark') {
                document.documentElement.classList.toggle('dark-theme');
            } else if (theme === 'light') {
                document.documentElement.classList.toggle('light-theme');
            }
        }
    }

    // Listen for a click on the theme change button 
    themeButton.forEach(item => {
        item.addEventListener("click", function() {
            // If the OS is set to dark mode...
            if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                document.documentElement.classList.toggle("light-theme");
            } else {
                // ...apply the .dark-theme class to override the default light styles
                document.documentElement.classList.toggle("dark-theme");
            }

            // write info about selected theme to localstorage
            if (item.classList.contains('light-theme-element')) {
                const themePreference = {
                    theme: 'dark',
                    timestamp: Date.now(),
                };
                localStorage.setItem('theme', JSON.stringify(themePreference));                  
            } else if (item.classList.contains('dark-theme-element')) {
                const themePreference = {
                    theme: 'light',
                    timestamp: Date.now(),
                };
                localStorage.setItem('theme', JSON.stringify(themePreference));   
            }
        });

    });

}